import React, { useState } from 'react';
import EmployAside from "../aside";
import Rdv from "./rdv";  // Keep this import for the Rdv component from './rdv'

const RdvPage = () => {  // Rename this component to avoid conflict
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  return (    
    <div className="main-page-wrapper">
      {/* aside start */}
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar} />
      {/* aside end  */}

      {/* saved candidate area start */}
      <Rdv setIsOpenSidebar={setIsOpenSidebar} />  {/* Use imported Rdv component */}
      {/* saved candidate area end */}
    </div>    
  );
};

export default RdvPage;  // Export the renamed component
