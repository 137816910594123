"use client";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DashboardHeader from '../../header';

const RechecherSociete = ({ setIsOpenSidebar }) => {
  const [isOpen, setIsOpen] = useState(false); // State to manage modal visibility

  const handleAddClick = () => {
    setIsOpen(true); // Show the modal when the div is clicked
  };

  const handleCloseModal = () => {
    setIsOpen(false); // Hide the modal
  };

  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        <h2 className="main-title h2-titel">Ma facturation &gt; Projet nom &gt; Créer une facture</h2>
        
        {/* Div to trigger modal */}
        <div onClick={handleAddClick} style={{ padding: '10px', cursor: 'pointer', borderTop: '1px solid #ccc' }}>
          Recherche avancée
        </div>

        {/* Modal structure */}
        {isOpen && (
          <div className="modal fade show" style={{ display: 'block' }} aria-labelledby="modalLabel" aria-hidden="false">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="modalLabel">Recherche Avancée</h5>
                  <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <form className="text-center">
                    <p className="text-center">
                      A partir du registre de l’état, notre outil est capable de retrouver vos clients à partir d’une partie du nom ou du numéro de siret de l’entreprise ou du nom du dirigeant.
                    </p>

                    <div className='row justify-content-center'>
                      <div className="mb-3 col-md-8">
                        <input className="form-control mb-3" type="text" placeholder='Renseignez un nom d’entreprise, d’un dirigeant ou du numéro de siret' />
                      </div>
                    </div>

                    <div className="form-check text-start mb-3">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                      <label className="form-check-label" htmlFor="flexRadioDefault1">
                        Nom Société<br /> N° Siret<br /> Nom du dirigeant<br /> Adresse
                      </label>
                    </div>
                    <div className="form-check text-start mb-3">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                      <label className="form-check-label" htmlFor="flexRadioDefault2">
                        Nom Société<br /> N° Siret<br /> Nom du dirigeant<br /> Adresse
                      </label>
                    </div>
                    <div className="form-check text-start mb-3">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                      <label className="form-check-label" htmlFor="flexRadioDefault3">
                        Nom Société<br /> N° Siret<br /> Nom du dirigeant<br /> Adresse
                      </label>
                    </div>

                    <div className="button-group d-inline-flex align-items-center justify-content-center">
                      <Link to="/ma-facturation-factures-create" className="text-reset me-3 card_dash" onClick={handleCloseModal}>Annuler</Link>
                      <Link to="/ma-facturation-factures-create" className="dash-btn-two tran3s me-3 card_dash" onClick={handleCloseModal}>Ajouter</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RechecherSociete;
