import React,{useState} from 'react';
import EmployAside from "../aside";
import MesAchatsIndex from "./mes_achats";
import AchatCreate from './achatCreate';

const CreateAchat = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (    
    <div className="main-page-wrapper">
    {/* aside start */}
    <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar} />
    {/* aside end  */}

    {/* saved candidate area start */}
    <AchatCreate setIsOpenSidebar={setIsOpenSidebar} />
    {/* saved candidate area end */}
    </div>    
  );
};

export default CreateAchat;
