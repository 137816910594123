'use client'
import React, { useState } from 'react';
import EmployAside from '../../aside';
import DocumentSigner from './document-a-signer';

const MesDemarchesinfoSociete = () => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  return (
    <div className="main-page-wrapper">
      {/* aside start */}
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar} />
      {/* aside end */}

      {/* profile area start */}
      <DocumentSigner setIsOpenSidebar={setIsOpenSidebar} />
      {/* profile area end */}
    </div>
  );
};

export default MesDemarchesinfoSociete;
