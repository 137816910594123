import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaCheckCircle } from 'react-icons/fa';

const CandidateV3Area = ({ style_2 }) => {
    const [jobType, setJobType] = useState(style_2 ? "list" : "grid");

    return (
        <>
                    <div className="hero-banner-two position-relative">
                <div className="container">
                    <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop:'135px', paddingBottom:'30px' }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-8">              
                            <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">CRÉATION PACKAGE COMMERCIAL</h2>
                            </div>
                        </div>
                    </div>
                </div>                           
            </div>
            <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
                <div className="container">
                    <div className="text-center mb-4">
                        <div className="btn-group">
                            <Link to="/package-com1">
                                <button type="button" className="btn btn-success">
                                    {"< Précédent"}
                                </button>
                            </Link>
                            <span className="mx-3">2/15</span>
                        </div>
                    </div>
                    <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
                        <h4 className="fw-600">
                            Le package commercial comprend une adresse mail professionnelle contact@nom-de-domaine.fr/com.
                            Souhaitez-vous ajouter d’autres adresses ? (5 € HT / mois / adresse mail)
                        </h4>
                    </div>
                    <div className="user-data-form">
                        <div className="form-wrapper m-auto">
                            <form>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="input-group-meta position-relative mb-25">
                                            <label htmlFor="email1">Email 1*</label>
                                            <input
                                                type="email"
                                                id="email1"
                                                placeholder="jean.dupont@example.com"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="input-group-meta position-relative mb-25">
                                            <label htmlFor="email2">Email 2*</label>
                                            <input
                                                type="email"
                                                id="email2"
                                                placeholder="jean.dupont@example.com"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="input-group-meta position-relative mb-25">
                                            <label htmlFor="email3">Email 3*</label>
                                            <input
                                                type="email"
                                                id="email3"
                                                placeholder="jean.dupont@example.com"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="input-group-meta position-relative mb-25">
                                            <label htmlFor="email4">Email 4*</label>
                                            <input
                                                type="email"
                                                id="email4"
                                                placeholder="jean.dupont@example.com"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-3">
                                        <button type="submit" className="btn-eleven1 fw-500 tran3s d-block mt-20">
                                            <Link to="/package-com3" className="d-block">
                                                Ajouter
                                            </Link>
                                        </button>
                                       
                                    </div>
                                    </div>
                                    <div className="col-12 text-center mt-4">
                                        <button type="submit" className="btn-eleven1 fw-500 tran3s d-block mt-20">
                                            <Link to="/package-com3" className="d-block">
                                                Valider
                                            </Link>
                                        </button>
                                        <button type="submit" className="btn-eleven1 fw-500 tran3s d-block mt-20">
                                            <Link to="/package-com3" className="d-block">
                                                Passer cette étape
                                            </Link>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CandidateV3Area;
