import React from "react";
import { Link } from "react-router-dom";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineDot, TimelineContent } from '@mui/lab';
import { FaCheckCircle, FaTimes, FaThumbsUp, FaInfoCircle } from 'react-icons/fa';
import { Grid, Paper, Typography, Button, Container } from '@mui/material';
import { Stack } from '@mui/material';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BalanceIcon from '@mui/icons-material/Balance';
import TaskIcon from '@mui/icons-material/Task';


function PositionedTimeline() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <>
            <Timeline
                sx={{
                    [`& .${timelineItemClasses.root}:before`]: isSmallScreen
                        ? {
                            flex: 0,
                            padding: 0,
                        }
                        : undefined,
                }}
                position={isSmallScreen ? undefined : "alternate-reverse"}
            >
                <TimelineItem sx={{ alignItems: "center" }}>
                    <TimelineSeparator>
                        <TimelineConnector
                            sx={{ height: "50px", background: "transparent" }}
                        />
                        <TimelineDot
                            sx={{
                                backgroundColor: "rgb(162, 221, 199)",
                                borderColor: "white",
                                borderWidth: 5,
                                borderStyle: "solid",
                                width: 45,
                                height: 45,
                                margin: 0,
                            }}
                        />
                        <TimelineConnector sx={{ height: "50px" }} />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper elevation={3} sx={{ padding: 1 }}>
                            <Stack spacing={2} direction="row" alignItems={"center"}>
                                <ReceiptLongIcon
                                    sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                                />
                                <Typography align="right" style={{ fontSize: 'font-size: 1rem !important' }}>
                                    Étape 1
                                </Typography>
                            </Stack>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem sx={{ alignItems: "center" }}>
                    <TimelineSeparator>
                        <TimelineConnector sx={{ height: "50px" }} />
                        <TimelineDot
                            sx={{
                                backgroundColor: "rgb(162, 221, 199)",
                                borderColor: "white",
                                borderWidth: 5,
                                borderStyle: "solid",
                                width: 45,
                                height: 45,
                                margin: 0,
                            }}
                        />
                        <TimelineConnector sx={{ height: "50px" }} />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper elevation={3} sx={{ padding: 1 }}>
                            <Stack spacing={2} direction="row" alignItems={"center"}>
                                <RecordVoiceOverIcon
                                    sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                                />
                                <Typography align="left">
                                    Étape 2
                                </Typography>
                            </Stack>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem sx={{ alignItems: "center" }}>
                    <TimelineSeparator>
                        <TimelineConnector sx={{ height: "50px" }} />
                        <TimelineDot
                            sx={{
                                backgroundColor: "rgb(162, 221, 199)",
                                borderColor: "white",
                                borderWidth: 5,
                                borderStyle: "solid",
                                width: 45,
                                height: 45,
                                margin: 0,
                            }}
                        />
                        <TimelineConnector sx={{ height: "50px" }} />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper elevation={3} sx={{ padding: 1 }}>
                            <Stack spacing={2} direction="row" alignItems={"center"}>
                                <AccountBalanceIcon
                                    sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                                />
                                <Typography align="left">
                                    Étape 4
                                </Typography>
                            </Stack>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem sx={{ alignItems: "center" }}>
                    <TimelineSeparator>
                        <TimelineConnector sx={{ height: "50px" }} />
                        <TimelineDot
                            sx={{
                                backgroundColor: "rgb(162, 221, 199)",
                                borderColor: "white",
                                borderWidth: 5,
                                borderStyle: "solid",
                                width: 45,
                                height: 45,
                                margin: 0,
                            }}
                        />
                        <TimelineConnector sx={{ height: "50px" }} />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper elevation={3} sx={{ padding: 1 }}>
                            <Stack spacing={2} direction="row" alignItems={"center"}>
                                <BalanceIcon
                                    sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                                />
                                <Typography align="left">
                                    Étape 4
                                </Typography>
                            </Stack>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem sx={{ alignItems: "center" }}>
                    <TimelineSeparator>
                        <TimelineConnector sx={{ height: "50px" }} />
                        <TimelineDot
                            sx={{
                                backgroundColor: "rgb(162, 221, 199)",
                                borderColor: "white",
                                borderWidth: 5,
                                borderStyle: "solid",
                                width: 45,
                                height: 45,
                                margin: 0,
                            }}
                        />
                        <TimelineConnector
                            sx={{ height: "50px", background: "transparent" }}
                        />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper elevation={3} sx={{ padding: 1 }}>
                            <Stack spacing={2} direction="row" alignItems={"center"}>
                                <TaskIcon
                                    sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                                />
                                <Typography align="left">Étape 5</Typography>
                            </Stack>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </>
    );
}

const AccordionItem = ({ id, title, isShow, desc, parent }) => {
    return (
        <div className="accordion-item">
            <div className="accordion-header" id={`heading-${id}`}>
                <button
                    className={`accordion-button ccordion_item ${isShow ? '' : 'collapsed'}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse-${id}`}
                    aria-expanded={isShow ? 'true' : 'false'}
                    aria-controls={`collapse-${id}`}
                >
                    {title}
                </button>
            </div>
            <div
                id={`collapse-${id}`}
                className={`accordion-collapse collapse ${isShow ? 'show' : ''}`}
                aria-labelledby={`heading-${id}`}
                data-bs-parent={`#${parent}`}
            >
                <div className="accordion-body">
                    <p>
                        {desc}
                    </p>
                </div>
            </div>
        </div>
    );
};


const CandidateV3Area = ({ style_2 }) => {
    return (
        <>
            <div className="hero-banner-two position-relative background_section">
                <div className="container">
                    <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">Titre</h2>
                                <p className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">sous-titre</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="text-feature-three pt-20 xl-pt-30 position-relative" /* pt-225 xl-pt-200 lg-pt-150 md-pt-100 */>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 order-lg-last ms-auto">
                            <div className="wow fadeInRight">
                                <div className="accordion accordion-style-one color-two mt-40" id="accordionOne">
                                    <AccordionItem id='one' /* isShow={true} */ title="Définition du statut xxx" desc="text" parent='accordionOne' />
                                    <AccordionItem id='two' title="Pourquoi choisir le statut xxx" desc="text" parent='accordionOne' />
                                    <AccordionItem id='three' title="Quels sont les obligations comptables ?" desc="text" parent='accordionOne' />
                                    <AccordionItem id='for' title="Combien coûte la création d’une entreprise en xxx ?" desc="text" parent='accordionOne' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />
            <br />

            <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
            <div className="container">
            <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
                <h2 className="fw-600">Nos services et tarifs</h2>
            </div>
          <div className="row mt-5 mb-3">
            {/* Service Table */}
            <div className="col-12">
              <table className="table table-bordered">
                <thead>
                                    <tr>
                                        <th>Etapes de la création d’entreprise</th>
                                        <th className="text-center">Minimal</th>
                                        <th className="text-center">Premium</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Préparation de vos statuts et de votre dossier</td>
                                        <td className="text-center"><FaCheckCircle style={{ color: 'green' }} /></td>
                                        <td className="text-center"><FaCheckCircle style={{ color: 'green' }} /></td>
                                    </tr>
                                    <tr>
                                        <td>Entretien téléphonique d’une heure pour répondre à toutes vos questions</td>
                                        <td className="text-center"><FaTimes style={{ color: 'red' }} /></td>
                                        <td className="text-center"><FaCheckCircle style={{ color: 'green' }} /></td>
                                    </tr>
                                    <tr>
                                        <td>Vérification du dossier par nos experts</td>
                                        <td className="text-center"><FaTimes style={{ color: 'red' }} /></td>
                                        <td className="text-center"><FaCheckCircle style={{ color: 'green' }} /></td>
                                    </tr>
                                    <tr>
                                        <td>Envoi de l’annonce légale</td>
                                        <td className="text-center"><FaTimes style={{ color: 'red' }} /></td>
                                        <td className="text-center"><FaCheckCircle style={{ color: 'green' }} /></td>
                                    </tr>
                                    <tr>
                                        <td>Envoi du dossier au greffe</td>
                                        <td className="text-center"><FaTimes style={{ color: 'red' }} /></td>
                                        <td className="text-center"><FaCheckCircle style={{ color: 'green' }} /></td>
                                    </tr>
                                    <tr>
                                        <td>Obtention de l’extrait Kbis</td>
                                        <td className="text-center"><FaTimes style={{ color: 'red' }} /></td>
                                        <td className="text-center"><FaCheckCircle style={{ color: 'green' }} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: 'none' }}></td>
                                        <td className="text-center">
                                            <button type="button" className="btn-eleven1 fw-500 tran3s d-block mt-20">
                                                <Link to="/create_soc1">Choisir ce service</Link>
                                            </button>
                                        </td>
                                        <td className="text-center">
                                            <button type="button" className="btn-eleven1 fw-500 tran3s d-block mt-20">
                                                <Link to="/create_soc1">Choisir ce service</Link>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br />

                    <div className="title-one text-center mb-100 lg-mb-10 wow py-4 fadeInUp background_section">
                        <h2 className="fw-600 mb-4">Les étapes de création</h2>
                        <PositionedTimeline />
                    </div>                    
                    {/* New Card Section */}
                    <div className="row mt-5">
                        <div className="col-12 text-center">
                            <h4>
                                <h2 className="fw-600 mb-4">Simulez vos futures charges</h2>
                            </h4>
                            <div className="row mt-5">
                                <div className="col-12 text-center">
                                    <button type="button" className="btn-eleven fw-500 tran3s d-block mt-20">
                                        <Link to="/simulator-info">SIMULATEUR URSSAF</Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>  
                    </div>
              
            </section>
            {/* Add your CandidateFilterModal component here */}
        </>
    );
};

export default CandidateV3Area;
