import React from 'react';
import DashboardHeader from '../header';

const EmployProfileArea = ({setIsOpenSidebar}) => {
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

        <h3 className="d-flex flex-column align-items-center">Mes informations personnelles</h3>

        <div className="bg-white card-box border-20">
        <form >
        <div className="row mb-4">
                <div className="col-md-6 mb-4 ">
                  <label htmlFor="formGroupExampleInput">Mon prénom</label>
                  <input type="text" className="form-control" id="formGroupExampleInput" placeholder="xxxxxx" />
                </div>
                <div className="col-md-6 mb-4">
                  <label htmlFor="formGroupExampleInput2">Mon nom</label>
                  <input type="text" className="form-control" id="formGroupExampleInput2" placeholder="xxxxxx" />
                </div>
                <div className="col-md-6 mb-4">
                  <label htmlFor="formGroupExampleInput">Mon adresse mail</label>
                  <input type="text" className="form-control" id="formGroupExampleInput" placeholder="xxxxxx" />
                </div>
                <div className="col-md-6 mb-4">
                  <label htmlFor="formGroupExampleInput2">Mon numéro de téléphone</label>
                  <input type="tel" className="form-control" id="formGroupExampleInput2" placeholder="xxxxxx" />
                </div>
            </div>
        </form>
          </div>
      </div>
    </div>
  );
};

export default EmployProfileArea;