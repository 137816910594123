import React, { useState } from 'react';
import { FaFolder, FaTrash, FaEllipsisV, FaFile ,FaSearch} from 'react-icons/fa';
import { Modal, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DashboardHeader from '../header';

const DocumentSigner = ({ setIsOpenSidebar }) => {
  const [folders, setFolders] = useState([
    { name: 'Dossier 1', subfolders: ['Dossier 2'], files: [] },
    { name: 'Dossier 11', subfolders: [], files: [] }
  ]);


  
  return (
    <div className="dashboard-body">
         <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

        <h2 className=" d-flex flex-column align-items-center main-title h2-titel"> GED &gt;Projet nom &gt;Projet WebPro nom</h2>

        <div className="row">
  <div className="sidebar col-4">
    <h2 className=" d-flex flex-column  main-title h2-titel">Pharos GED</h2>
    <ul className="folder-tree">
      <li>Ma GED
        <ul>
          {folders.map((folder, index) => (
            <li key={index}>
              {folder.name}
              {folder.subfolders.length > 0 && (
                <ul>
                  {folder.subfolders.map((subfolder, i) => (
                    <li key={i}>{subfolder}</li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </li>
      <li><FaTrash /> Corbeille</li>
    </ul>
  </div>

  <div className="card content-area col-8">
    <div className="search-bar mt-0 position-relative">
      <input type="text" placeholder="Rechercher dans ma GED" className="search-input" />
      <span className="search-icon"></span>
    </div>
    <div className="breadcrumb">Ma Corbeille</div>

    <div className="row mt-4">
      {[
        { name: "Nom dossier 1", type: "folder" },
        { name: "Nom dossier 2", type: "folder" },
        { name: "Nom dossier 3", type: "folder" },
      ].map((item, index) => (
        <div key={index} className="col-md-4 mb-3">
          <Card className="h-40">
            <Card.Body className="position-relative">
              <Card.Title className='fadeInUp'>
                <FaFolder /> {item.name}
              </Card.Title>
              <div className="action-dots float-end" style={{ top: '0', right: '0' }}>
                <button
                  className="action-btn dropdown-toggle position-absolute"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ top: '0px', right: '0px' }}
                >
                  <FaEllipsisV />
                </button>
                <ul className="dropdown-menu dropdown-menu-end custom-dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="#">
                      <img
                        src="https://img.icons8.com/ios-filled/24/000000/undo.png"
                        alt="Restaurer icon"
                        className="lazy-img"
                        style={{ width: '20px', height: '20px' }}
                      /> Restaurer
                    </Link>
                  </li>
                </ul>
              </div>
            </Card.Body>
          </Card>
        </div>
      ))}

      {/* Files section */}
      {[{ name: "Nom fichier 1", type: "file" },
        { name: "Nom fichier 2", type: "file" }
      ].map((item, index) => (
        <div key={index} className="col-md-4 mb-3">
          <Card className="h-40">
            <Card.Body className="position-relative">
              <Card.Title className='fadeInUp'>
                <FaFile /> {item.name}
              </Card.Title>
              <div className="action-dots float-end" style={{ top: '', right: '0' }}>
                <button
                  className="action-btn dropdown-toggle position-absolute"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ top: '0px', right: '0px' }}
                >
                  <FaEllipsisV />
                </button>
                <ul className="dropdown-menu dropdown-menu-end custom-dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="#">
                      <img
                        src="https://img.icons8.com/ios-filled/24/000000/undo.png"
                        alt="Restaurer icon"
                        className="lazy-img"
                        style={{ width: '20px', height: '20px' }}
                      /> Restaurer
                    </Link>
                  </li>
                </ul>
              </div>
            </Card.Body>
          </Card>
        </div>
      ))}
    </div>

    <style jsx>{`
        .dashboard-body {
          display: flex;
        }
        .sidebar {
          width: 250px;
          background-color: rgb(25, 175, 120) !important;
          color: white;
          padding: 20px;
        }
          .card{
                    background-color: white;

          }
        .folder-tree {
          list-style: none;
          padding-left: 0;
        }
        .folder-tree li {
          margin-bottom: 10px;
        }
        .content-area {
          flex: 1;
          padding: 20px;
        }
        .search-bar input {
          width: 100%;
          padding: 10px;
          margin-bottom: 10px;
          border-radius: 5px;
          border: 1px solid #ccc;
        }
        .actions {
          display: flex;
          gap: 10px;
          margin-bottom: 20px;
        }
        .actions button {
          padding: 10px 15px;
          background-color: #ccc;
          border: none;
          cursor: pointer;
          border-radius: 5px;
        }
        .breadcrumb {
          margin-bottom: 20px;
        }

        .search-bar {
  display: flex;
  align-items: center;
}

.search-input {
  width: 100%; /* Full width */
  padding: 10px 30px 10px 10px; /* Padding for space */
  border: 1px solid #ccc;
  border-radius: 4px;
}
@media (max-width: 768px) {
  .breadcrumb {
    font-size: 14px; /* Adjust breadcrumb font size */
  }

  .search-bar input {
    padding: 8px; /* Reduce padding for mobile */
  }

  .card-title {
    font-size: 16px; /* Adjust font size of card titles */
  }

  .actions {
    flex-direction: column; /* Stack buttons vertically */
    gap: 5px; /* Reduce space between buttons */
  }
}



      `}</style>
  </div>
</div>

    </div> 
    </div>
     
  
  );
};

export default DocumentSigner;
