import React, { useState } from "react";
import EmployAside from "../aside";
import IndexGed from "./ged-index";

const EmployDashboardSubmitJobPage = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (    
    <div className="main-page-wrapper">
    {/* aside start */}
    <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar} />
    {/* aside end  */}

    {/* submit job area start */}
    <IndexGed setIsOpenSidebar={setIsOpenSidebar} />
    {/* submit job area end */}
    </div>    
  );
};

export default EmployDashboardSubmitJobPage;
