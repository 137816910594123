import React from 'react';
import DashboardHeader from '../header';
import { Link } from 'react-router-dom';

const IndexGed = ({setIsOpenSidebar}) => {
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

          <h2 className="main-title h2-title text-center">GED</h2>          

        <div className="bg-white card-box border-20">
          <div className="row justify-content-center">
             <div className="col-12">
                        <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                        <Link to="/ged">Porjet WebPro 1</Link>
                        </button>
                        <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                         <Link to="#">projet WebPro 2</Link>
                        </button>
                        <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                         <Link to="/package-com">Commander un nouveau projet WebPro</Link>
                        </button>
                        </div>
                         
          </div>
        </div>
          
      </div>      
    </div>
  );
};

export default IndexGed;