import React,{useState} from 'react';
import EmployAside from '../../aside';
import DevisIndex from './devis';

const Devis = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (    

    <div className='main-page-wrapper'>
      {/* aside start */}
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar}/>
      {/* aside end  */}

      {/* profile area start */}
      <DevisIndex setIsOpenSidebar={setIsOpenSidebar}/>
      {/* profile area end */}
    </div>    
  );
};

export default Devis;