import React,{useState} from 'react';
import DashboardHeader from '../../header';
import {Link} from "react-router-dom";
import { Modal, Button } from 'react-bootstrap'; // Assurez-vous d'avoir installé react-bootstrap

const Info = ({ setIsOpenSidebar }) => {
  const [isOpenGeneral, setIsOpenGeneral] = useState(false);
  const [isOpenGallery, setIsOpenGallery] = useState(false);
  const [isOpenLogo, setIsOpenLogo] = useState(true);
  const [showModal, setShowModal] = useState(false); // Controls the modal visibility
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploadOpen, setIsUploadOpen] = useState(false);

  // Function to handle the file selection


  // Function to show the modal
  const handleUploadClick = () => {
    console.log("Upload button clicked!"); // Debugging statement
    setShowModal(true); // Open the modal
  };

  // Function to handle the file upload
  const handleUpload = () => {
    if (selectedFile) {
      console.log("File to upload:", selectedFile);
      // Implement file upload logic here
    }
    setShowModal(false); // Close the modal after handling the upload
  };

  const toggleAccordionGeneral = () => setIsOpenGeneral(!isOpenGeneral);
  const toggleAccordionLogo = () => setIsOpenLogo(!isOpenLogo);
  const toggleAccordionGallery = () => setIsOpenGallery(!isOpenGallery);
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file); // You can implement further logic for handling the file
  };
  



  const handleClosePopup = () => {
    setIsUploadOpen(false); // Close the popup
  };
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

        <h2 className="main-title text-center h2-titel"> Pharos WebPro &gt; projet nom</h2>

        <div className="bg-white card-box border-20 col-12">
  {/* Accordéon pour Informations générales */}
  <h4 onClick={toggleAccordionGeneral} style={{ cursor: 'pointer' }}>
    &gt;Informations générales
  </h4>

  {isOpenGeneral && (
    <div className="mb-30">
      <div className="mt-30">
        <div className="row">
          <div className="row mb-4">
            <div className="col-md-6">
              <label htmlFor="domainInput">Nom de domaine</label>
              <input type="text" className="form-control" id="domainInput" placeholder="xxxxxx" />
            </div>
            <div className="col-md-6">
              <label htmlFor="companyInput">Nom société</label>
              <input type="text" className="form-control" id="companyInput" placeholder="xxxxxx" />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-6">
              <label htmlFor="addressInput">Adresse</label>
              <input type="text" className="form-control" id="addressInput" placeholder="xxxxxx" />
            </div>
            <div className="col-md-6">
              <label htmlFor="phoneInput">Numéro de téléphone</label>
              <input type="text" className="form-control" id="phoneInput" placeholder="xxxxxx" />
            </div>
          </div>

          {/* Horaires Section */}
          <div className="row mb-4">
            <div className="col-md-12">
              <h5>Horaires</h5>
              <div className="horaires-wrapper border p-3" style={{ borderRadius: '10px' }}>
                <div className="row mb-2">
                  <div className="col"></div> {/* Empty for labels on the left */}
                  <div className="col text-center">Début matinée</div>
                  <div className="col text-center">Fin matinée</div>
                  <div className="col text-center">Début après-midi</div>
                  <div className="col text-center">Fin après-midi</div>
                </div>
                {['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'].map((day) => (
                  <div className="row mb-2 align-items-center" key={day}>
                    <div className="col" style={{ width: '100px' }}>{day}</div>
                    <div className="col">
                      <input type="text" className="form-control" placeholder="xxxxxx" />
                    </div>
                    <div className="col">
                      <input type="text" className="form-control" placeholder="xxxxxx" />
                    </div>
                    <div className="col">
                      <input type="text" className="form-control" placeholder="xxxxxx" />
                    </div>
                    <div className="col">
                      <input type="text" className="form-control" placeholder="xxxxxx" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <h5>A propos</h5>
              <textarea type="text" className="form-control" id="aboutInput" placeholder="xxxxxx" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )}

  <h4 onClick={toggleAccordionLogo} style={{ cursor: 'pointer' }}>
    &gt;Logo
  </h4>

  <>
  {isOpenLogo && (
        <div className="text-center mb-30">
          <div className="d-flex justify-content-center mt-30">
            <img
              src="./assets/images/logo/logo-pharos.png"
              alt="logo"
              width="100"
              priority="true"
            />
          </div>
          <div className="d-flex justify-content-center mt-4">
            <button onClick={handleUploadClick} className="dash-btn-two tran3s me-3">
             Modifier
            </button>
          </div>
        </div>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload un nouveau fichier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="file" onChange={handleFileChange} />
          {selectedFile && <p>Fichier sélectionné: {selectedFile.name}</p>}
        </Modal.Body>
        <Modal.Footer>
          
          <Link
          to="#"
                      style={{
                        backgroundColor: '#19AF78',
                        color: 'white',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        textDecoration: 'none',
                        transition: 'all 0.3s ease'
                      }}
                      onClick={handleUpload}
                    >
                      Charger
                    </Link>
       
        </Modal.Footer>
      </Modal>
    </>
  <h4 onClick={toggleAccordionGallery} style={{ cursor: 'pointer' }}>
    &gt;Galleries images
  </h4>

  {isOpenGallery && (
    <>
    <div className="text-center mb-30">
      <div className="d-flex justify-content-center mt-30 row">
        <div className='col-md-3 mb-3'>
          <div className="gallery-item gallery-item-card">
            <img src="./assets/images/logo/logo-pharos.png" style={{paddingTop:"30%"}} className='mx-auto d-block ' alt="image1"  width="100" />
          </div>
        </div>
        <div className='col-md-3 mb-3'>
          <div className="gallery-item gallery-item-card">
            <img src="./assets/images/logo/logo-pharos.png" style={{paddingTop:"30%"}} className='mx-auto d-block ' alt="image1"  width="100" />
          </div>
        </div>
        <div className='col-md-3 mb-3'>
          <div className="gallery-item gallery-item-card">
            <img src="./assets/images/logo/logo-pharos.png" style={{paddingTop:"30%"}} className='mx-auto d-block ' alt="image1"  width="100" />
          </div>
        </div>
        <div className='col-md-3 mb-3'>
          <div className="gallery-item gallery-item-card">
            <img src="./assets/images/logo/logo-pharos.png" style={{paddingTop:"30%"}} className='mx-auto d-block ' alt="image1"  width="100" />
          </div>
        </div>
      </div>
    </div>
     <div className="d-flex justify-content-center mt-4">
     <Link to="/package-com1" className="dash-btn-two tran3s me-3">Modifier le package</Link>
   </div>
   </>
  )}

 
</div>

      </div>
    </div>
  );
};

export default Info;
