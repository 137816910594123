import React, { useState } from "react";
import { Link } from "react-router-dom";


const CandidateV3Area = ({ style_2 }) => {
  const [jobType, setJobType] = useState(style_2 ? "list" : "grid");

  // Example image paths (adjust these paths to match your actual file structure)
  const imageCatalog = [
    "/assets/images/blog/Gerer_ma_societe.png",
    "/assets/images/blog/Creer_mon_paquet_web.png",
    "/assets/images/blog/Creer_ma_societe.png",
    "/assets/images/blog/Creer_mon_paquet_web.png",
    // Add more images as needed
  ];

  return (
    <>
                <div className="hero-banner-two position-relative">
                <div className="container">
                    <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop:'135px', paddingBottom:'30px' }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-8">              
                            <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">CRÉATION PACKAGE COMMERCIAL</h2>
                            </div>
                        </div>
                    </div>
                </div>                           
            </div>
      <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
        <div className="container">
          <div className="text-center mb-4">
            <div className="btn-group">
              <Link to="/package-com10">
                <button type="button" className="btn btn-success">
                  {"< Précédent"}
                </button>
              </Link>
              <span className="mx-3">14/15</span>
            </div>
          </div>
          <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
            <h4 className="fw-600">Ajoutez des images à votre site vitrine</h4>
          </div>
          <div className="col-12 mb-4">
            <div className="input-group-meta position-relative mb-25">
              <label htmlFor="slogan">Chargez vos images</label>
              <input
                type="file"
                id="slogan"
                placeholder="Horaire"
                className="form-control"
              />
            </div>
          </div>
          <div className="text-center mb-4">
            <h5 className="fw-500">Sélectionnez des images depuis notre catalogue d’images</h5>
            <div className="row">
              {imageCatalog.map((image, index) => (
                <div className="col-md-3 mb-4" key={index}>
                  <div className="card">
                    <img src={image} className="card-img-top" alt={`Image ${index + 1}`} />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12">

              <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/package-com12" className="d-block">
                  Validez
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
