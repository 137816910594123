import React, { useState } from 'react';
import EmployAside from './employ/aside';
import EmployDashboardArea from './employ/dashboard-area';
import Footer from '../../layouts/Footer';

const DashboardMain = () => {
    const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  return (
    <div className='main-page-wrapper'>
      {/* aside start */}
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar} />
      {/* aside end  */}

      {/* dashboard area start */}
      <EmployDashboardArea setIsOpenSidebar={setIsOpenSidebar} />
      {/* dashboard area end */}      
    </div>
  );
};

export default DashboardMain;