import React from 'react';
import Header from "../Header";
import Banner from '../Banner';
import Blog from '../Blog';
import Feature from '../Feature';
import HowItWorksTwo from '../how-it-works';
import Footer from '../Footer';
import ScrollToTopButton from '../../Components/Theme/Theme';
import Creeat_societe from '../../Components/frontend/package-com/package-com14-area';


function Create_soc() {
    return (
        <>
            <Header></Header>
            <Creeat_societe></Creeat_societe>
            <Footer></Footer>                      
            <ScrollToTopButton></ScrollToTopButton>                      
        </>
    );
}

export default Create_soc;
