import React ,{useState} from 'react';
import DashboardHeader from '../../header';
/* import '@fortawesome/fontawesome-free/css/all.min.css'; */
import { Drawer } from "@mui/material"; // Import de Material UI (à adapter si vous utilisez un autre framework)
import { Modal, Button } from 'react-bootstrap'; // Assurez-vous d'avoir installé react-bootstrap
import AddAssocie from '../../../../../layouts/AddAssocie';

// props type 

const InformationsSociete = ({setIsOpenSidebar}) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleIconClick = (status) => {
    console.log(status); 
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (selectedFile) {
      console.log("Uploading: ", selectedFile);
    }
    setShowModal(false);
  };
  const handleIconClick1 = (iconClass) => {
    if (iconClass.includes("text-success")) {
      setDrawerOpen(true);
    }
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  const [isOpenGeneral, setIsOpenGeneral] = useState(false);
  const [isOpenEntreprise, setIsOpenEntreprise] = useState(false);
  const [isOpenDocument, setIsOpenDocument] = useState(false);
  const [isOpenDerigeant, setIsOpenDerigeant] = useState(false);
  const [isOpenAssocie, setIsOpenAssocie] = useState(false);

  const toggleAccordionAssocie = () => setIsOpenAssocie(!isOpenAssocie);

  const toggleAccordionGeneral = () => setIsOpenGeneral(!isOpenGeneral);
  const toggleAccordionEntreprise = () => setIsOpenEntreprise(!isOpenEntreprise);
  const toggleAccordionDocument = () => setIsOpenDocument(!isOpenDocument);
  const toggleAccordionDerigeant = () => setIsOpenDerigeant(!isOpenDerigeant);
  const [showModalAddAssocie, setShowModalAddAssocie] = useState(false);

  const handleOpenAddAssocie = () => setShowModalAddAssocie(true);
  const handleCloseAddAssocie = () => setShowModalAddAssocie(false);
  const handleAddAssocie = () => {
    console.log('Associé added');
    handleCloseAddAssocie(); 
  };
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        <h2 className="main-title  h2-titel text-center">Informations de la société <br /> nom-projet</h2>         


        <div className="bg-white card-box border-20">
        <div className="container">
          
  <div className="row">
    <div className="col-md-6">
      <h4 onClick={toggleAccordionDerigeant} style={{ cursor: 'pointer' }}> &gt; Fiche dirigeant</h4>
      
        
      <div className="mb-4">
        <input type="text" className="form-control" id="firstNameInput" placeholder="Prénom" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="lastNameInput" placeholder="Nom" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="birthPlaceInput" placeholder="Lieu de naissance" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="birthDateInput" placeholder="Date de naissance" />
      </div>
      <div className="mb-4">
        <input type="email" className="form-control" id="emailInput" placeholder="Email" />
      </div>
      <div className="mb-4">
        <input type="tel" className="form-control" id="phoneInput" placeholder="Tel" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="addressInput" placeholder="Adresse" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="personalSituationInput" placeholder="Situation personnelle" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="professionalSituationInput" placeholder="Situation professionnelle" />
      </div>
    </div>

    {/* Conjoint(e) */}
    <div className="col-md-6">
      <h4>Conjoint(e)</h4>
      <div className="mb-4">
        <input type="text" className="form-control" id="spouseFirstNameInput" placeholder="Prénom" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="spouseLastNameInput" placeholder="Nom" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="spouseMaidenNameInput" placeholder="Nom de jeune fille" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="spouseBirthPlaceInput" placeholder="Lieu de naissance" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="spouseBirthDateInput" placeholder="Date de naissance" />
      </div>
    </div>
  </div>
</div>

<hr className="mb-30 mt-30" />

  <h4 onClick={toggleAccordionDocument} style={{ cursor: 'pointer' }}> &gt; Document à fournir</h4>
  {isOpenDocument && (
  <div className="table-responsive mt-20 mb-20" id="liste-table">
  <table className="table job-alert-table">
        <tbody>
          <tr>
            <td className="text-left p-2">nom pièce 1</td>
            <td className="text-center p-2">
              <i
                className="fas fa-file-alt text-success fs-4"
                onClick={() => handleIconClick1("text-success")}
                style={{ cursor: "pointer" }}
              ></i>
            </td>
            <td className="text-center p-2">En attente/validé/rejeté</td>
            <td className="text-center p-2">message du cabinet comptable</td>
            <td className="text-center p-2">
              <button onClick={() => setShowModal(true)}>Charger</button>
            </td>
          </tr>
          <tr>
            <td className="text-left p-2">nom pièce 2</td>
            <td className="text-center p-2">
              <i
                className="fas fa-file-alt text-danger fs-4"
                onClick={() => handleIconClick("text-danger")}
                style={{ cursor: "pointer" }}
              ></i>
            </td>
            <td className="text-center p-2">En attente/validé/rejeté</td>
            <td className="text-center p-2">message du cabinet comptable</td>
            <td className="text-center p-2">
              <button onClick={() => setShowModal(true)}>Charger</button>
            </td>
          </tr>
        </tbody>
      </table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload un nouveau fichier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="file" onChange={handleFileChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleUpload}>
            Charger
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Drawer */}
      
      
     
   
  </div>
  )}
  
  <hr className="mb-30 mt-30" />
  <h4 onClick={toggleAccordionEntreprise} style={{ cursor: 'pointer' }}> &gt; Fiche entreprise</h4>
  {isOpenEntreprise && (
    <>
  <div className="row mb-4">
    <div className="col-md-6">
      <label htmlFor="companyStatus">Statut</label>
      <input type="text" className="form-control" id="companyStatus" placeholder="xxxxxx" />
    </div>
    <div className="col-md-6">
      <label htmlFor="companyName">Dénomination</label>
      <input type="text" className="form-control" id="companyName" placeholder="xxxxxx" />
    </div>
  </div>
  <div className="row mb-4">
    <div className="col-md-12">
      <label htmlFor="addressInput">Adresse du siège social juridique</label>
      <input type="text" className="form-control" id="addressInput" placeholder="xxxxxx" />
    </div>
  </div>
 <div className="row mb-4">
    <div className="col-md-6">
      <label htmlFor="companyPurpose">Objet</label>
      <input type="text" className="form-control" id="companyPurpose" placeholder="xxxxxx" />
    </div>
    <div className="col-md-6">
      <label htmlFor="companyCapital">Capital</label>
      <input type="text" className="form-control" id="companyCapital" placeholder="xxxxxx" />
    </div>
  </div>
  <div className="row mb-4">
    <div className="col-md-6">
      <label htmlFor="companyShares">Nombre de parts</label>
      <input type="text" className="form-control" id="companyShares" placeholder="xxxxxx" />
    </div>
    <div className="col-md-6">
      <label htmlFor="companyPartners">Nombre d'associés</label>
      <input type="text" className="form-control" id="companyPartners" placeholder="xxxxxx" />
    </div>
  </div>
  </>
  )}
  <hr className="mb-30 mt-30" />
  <div className="row">
     <h4 onClick={toggleAccordionGeneral} style={{ cursor: 'pointer' }}> &gt;Fiche associés</h4>
     {isOpenGeneral && (
    <>
    <div className="row"> {/* Add a row for the input fields */}
      <div className="col-md-6">
        <h4  onClick={toggleAccordionAssocie}> &gt; Associé 1</h4>
        {isOpenAssocie && (
          <>
        <div className="mb-4">
          <input type="text" className="form-control" id="firstNameInput" placeholder="Prénom" />
        </div>
        <div className="mb-4">
          <input type="text" className="form-control" id="lastNameInput" placeholder="Nom" />
        </div>
        <div className="mb-4">
          <input type="text" className="form-control" id="birthPlaceInput" placeholder="Lieu de naissance" />
        </div>
        <div className="mb-4">
          <input type="text" className="form-control" id="birthDateInput" placeholder="Date de naissance" />
        </div>
        <div className="mb-4">
          <input type="email" className="form-control" id="emailInput" placeholder="Email" />
        </div>
        <div className="mb-4">
          <input type="tel" className="form-control" id="phoneInput" placeholder="Tel" />
        </div>
        <div className="mb-4">
          <input type="text" className="form-control" id="addressInput" placeholder="Adresse" />
        </div>
        <div className="mb-4">
          <input type="text" className="form-control" id="personalSituationInput" placeholder="Situation personnelle" />
        </div>
        <div className="mb-4">
          <input type="text" className="form-control" id="professionalSituationInput" placeholder="Situation professionnelle" />
        </div>
        </>
        )}
      </div>

      {/* Conjoint(e) */}
      <div className="col-md-6">
        <h4>Conjoint(e)</h4>
        <div className="mb-4">
          <input type="text" className="form-control" id="spouseFirstNameInput" placeholder="Prénom" />
        </div>
        <div className="mb-4">
          <input type="text" className="form-control" id="spouseLastNameInput" placeholder="Nom" />
        </div>
        <div className="mb-4">
          <input type="text" className="form-control" id="spouseMaidenNameInput" placeholder="Nom de jeune fille" />
        </div>
        <div className="mb-4">
          <input type="text" className="form-control" id="spouseBirthPlaceInput" placeholder="Lieu de naissance" />
        </div>
        <div className="mb-4">
          <input type="text" className="form-control" id="spouseBirthDateInput" placeholder="Date de naissance" />
        </div>
      </div>
    </div>

    <div className="table-responsive mt-20 mb-20" id="liste-table">
      <table className="table job-alert-table">
        <tbody>
          <tr>
            <td className="text-left p-2">nom pièce 1</td>
            <td className="text-center p-2">
              <i className="fas fa-file-alt text-success fs-4" onClick={() => handleIconClick1("text-success")}></i>
            </td>
            <td className="text-center p-2">En attente/validé/rejeté</td>
            <td className="text-center p-2">message du cabinet comptable</td>
            <td className="text-center p-2">
              <button onClick={() => setShowModal1(true)}>Charger</button>
            </td>
          </tr>
          <tr>
            <td className="text-left p-2">nom pièce 2</td>
            <td className="text-center p-2">
              <i className="fas fa-file-alt text-danger fs-4"></i>
            </td>
            <td className="text-center p-2">En attente/validé/rejeté</td>
            <td className="text-center p-2">message du cabinet comptable</td>
            <td className="text-center p-2">
              <button onClick={() => setShowModal1(true)}>Charger</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    {/* Button to trigger the modal */}
    <div className="d-flex justify-content-start mt-4 mb-3">
      <button className="dash-btn-two tran3s me-3" onClick={handleOpenAddAssocie}>
        Ajouter un associé
      </button>
    </div>

    <AddAssocie
      show={showModalAddAssocie}
      handleClose={handleCloseAddAssocie}
      handleAddAssocie={handleAddAssocie}
    />

    
  </>
  )}
</div>          
<div className="d-flex justify-content-center mt-4">
      <button className="dash-btn-two tran3s me-3">
        Mettre à jour
      </button>
    </div>
      <Modal show={showModal1} onHide={() => setShowModal1(false)}>
        <Modal.Header closeButton>
          <Modal.Title> Charger un nouveau fichier </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="file" onChange={handleFileChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal1(false)}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleUpload}>
            Charger
          </Button>
        </Modal.Footer>
      </Modal>
      </div>   

      <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer}>
        <div style={{ width: 300, padding: 20 }}>
          <button onClick={closeDrawer}>Fermer</button>
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <img
              src="./assets/images/logo/logo-pharos.png" 
              alt="Document"
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
        </div>
      </Drawer>
</div>
    </div>
  );
};

export default InformationsSociete;