import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Use react-router-dom for routing

import { FaUserAlt, FaUserFriends, FaSlack } from 'react-icons/fa'; // Imported relevant icons

const CandidateV3Area = ({ style_2 }) => {
  const [jobType, setJobType] = useState(style_2 ? 'list' : 'grid');

  return (
    <>

<div className="hero-banner-two position-relative">
                <div className="container">
                    <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">Choisir son statut</h2>
                                {/* <p className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">sous-titre</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      <section className="blog-section-two pt-20 xl-pt-30 lg-pt-100 pb-150 xl-pb-130 lg-pb-80">
        <div className="container">
          <div className="text-center mb-4">
            <div className="btn-group">
              <Link to="/activite-statut">
                <button type="button" className="btn btn-success">
                  {"< Précédent"}
                </button>
              </Link>
              <span className="mx-3">2/5</span>
              <Link to="activite-statut-s1">
                <button type="button" className="btn btn-success">
                  {"Suivant >"}
                </button>
              </Link>
            </div>
            <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
              <h2 className="fw-600">Quelle sera l’activitée exercée ?</h2>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-12">
              <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/activite-statut-s2">
                  <FaUserAlt className="me-2" /> Activité commerciale
                </Link>
              </button>
              <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/activite-statut-s2">
                  <FaUserFriends className="me-2" /> Activité civile (non commerciale) avec de l’immobilier
                </Link>
              </button>
              <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/activite-statut-s1" >
                  <FaSlack className="me-2" /> Profession libérale réglementée
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default CandidateV3Area;
