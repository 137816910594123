import React from "react";
import { Link } from "react-router-dom";
import { FaFileInvoice, FaFileAlt } from 'react-icons/fa'; // Importing icons for Facturation and Devis

const CandidateV3Area = ({ style_2 }) => {
  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">CRÉATION D’XXXXX</h2>
                {/* <p className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">sous-titre</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="blog-section-two pt-0 xl-pt-20 lg-pt-30 pb-30 xl-pb-30  lg-pb-30 ">
        <div className="container">
          <div className="text-center mb-4">
            <h2 className="fw-600">
              Ajoutez d’autres services Pharos pour une commande plus complète
            </h2>
            <br /> <br />
            <div className="btn-group">
              <Link to="/create_soc">
                <button type="button" className="btn btn-success">
                  {"< Précédent"}
                </button>
              </Link>
              <span className="mx-3">1/4</span>
            </div>
          </div>
         <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
            <h3 className="fw-600 mb-4">
              Pharos Compta
            </h3>
            <p className="fw-600">
              Un service <strong>gratuit</strong> pour générer et stocker rapidement vos<br /> 
              factures et devis. Un service <strong>disponible</strong> à partir <strong>de la création<br /> 
              d’un compte Pharos</strong>
            </p>

            <div className="d-flex flex-column align-items-center justify-content-center mt-4">
            <div className="d-flex justify-content-center align-items-center ">
              <div className="feature-list" style={{ width: '100%', maxWidth: '600px' }}>
                <div className="d-flex align-items-center mb-4">
                  <FaFileInvoice size={40} className="text-success me-3" />
                  <div>
                    <h4 className="mb-0">Facturation</h4>
                  </div>
                </div>

                <div className="d-flex align-items-center mb-4">
                  <FaFileAlt size={40} className="text-success me-3" />
                  <div>
                    <h4 className="mb-0">Devis</h4>
                  </div>
                </div>
              </div>
        </div>
    </div>
    <div className="row mt-5">
                <div className="col-12 text-center">
                  <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                    <Link to="/create_soc2">Pour suivre la commande → </Link>
                  </button>
                </div>
              </div>
    </div>

        </div>
      </section>
      {/* Add your CandidateFilterModal component here */}
    </>
  );
};

export default CandidateV3Area;
