import React from 'react';
import '../css/Home.css';
import Header from "./Header";
import Banner from './Banner';
import Blog from './Blog';
import Feature from './Feature';
import HowItWorksTwo from './how-it-works';
import Footer from './Footer';
import ScrollToTopButton from '../Components/Theme/Theme';


function Home() {
    return (
        <>
            <Header></Header>
            <Banner></Banner>
            <Blog></Blog>
            <Feature></Feature>
            <HowItWorksTwo></HowItWorksTwo>
            <Footer></Footer>                      
            <ScrollToTopButton></ScrollToTopButton>                      
        </>
    );
}

export default Home;
