import React, { useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Import your icon components or use icon libraries
import { FaCalendarDay, FaCommentDots, FaQuestionCircle, FaCheckCircle } from 'react-icons/fa';

const CandidateV3Area = ({ style_2 }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">Récapitulatif</h2>
                {/* <p className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">Sélectionnez les thèmes que vous souhaitez aborder avec nos experts (plusieurs choix possibles)</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="candidates-profile pt-90 lg-pt-70 pb-160 xl-pb-150 lg-pb-80" style={{ backgroundColor: '#FFF' }}>
        <div className="container">
          <div className="text-center mb-4">
            {/* You can add content here if needed */}
          </div>
          <div className="row mt-5">
            {/* Date and Time Picker Section */}
            <div className="col-12 text-center mt-4">
              {/* <h3 className="mb-4">Récapitulatif</h3> */}
              <div className="card p-4 mb-4">
                <p>
                  Vous avez bien pris rendez-vous le {selectedDate?.toLocaleDateString("fr-FR")} à {selectedDate?.toLocaleTimeString("fr-FR")}.
                  Un mail récapitulatif vous sera envoyé.
                </p>
                <p>
                  Nous nous chargerons de vous appeler et de répondre à toutes vos questions.
                </p>
              </div>
              <button type="button" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/">Retourner à la page d’accueil</Link>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
