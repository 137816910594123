import React from "react";
import { Link } from "react-router-dom"; // Use react-router-dom for routing
import { FaFileInvoice, FaGlobe, FaInfoCircle } from 'react-icons/fa';

const CandidateV3Area = () => {
  return (
    <>
      <section className="order-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              {/* Left Section */}
              <div className="left-section">
                <div className="mb-4">
                  <Link to="/create_soc4">
                    <button type="button" className="btn btn-success">
                      {"< Précédent"}
                    </button>
                  </Link>
                  <Link to="#" className="float-right" style={{float:'right'}}>
                    <button type="button" className="btn btn-success">
                      Modifier
                    </button>
                  </Link>
                </div>

                <h2 className="section-title">Votre commande</h2>
                <h3 className="sub-title">Création de xxx</h3>

                <div className="service-info">
                  <table className="pricing-table">
                    <thead>
                      <tr>
                        <th className="service-title">
                          <FaFileInvoice /> Pharos Legal Minimal ou Premium
                        </th>
                        <th className="pricing-item">
                          15/500 € HT
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="2">
                          <ul>
                            <li>Préparation de vos statuts et de votre dossier</li>
                            <li>Entretien téléphonique d’une heure pour répondre à toutes vos questions</li>
                            <li>Vérification du dossier par nos experts</li>
                            <li>Envoi de l’annonce légale</li>
                            <li>Envoi du dossier au greffe</li>
                            <li>Obtention de l’extrait Kbis</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="pricing-table">
                    <thead>
                      <tr>
                        <th className="service-title">
                          <FaGlobe /> Domiciliation avec Pharos Legal (si sélectionné)
                        </th>
                        <th className="pricing-item">
                          49.90 € HT / mois
                        </th>
                      </tr>
                    </thead>
                  </table>

                  <table className="pricing-table">
                    <thead>
                      <tr>
                        <th className="service-title">
                          <FaFileInvoice /> Pharos WebPro (si sélectionné)
                        </th>
                        <th className="pricing-item ">
                          49.90 € HT / mois
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              {/* Right Section */}
              <div className="right-section card mb-4">
                <div className="card-header">
                  <h3>Récapitulatif</h3>
                </div>
                <div className="card-body">
                  <p>Total commande HT: xx €</p>
                  <p>TVA <FaInfoCircle /> : yy €</p>
                  <p>Total TTC: zz €</p>
                  <h4>Nommez votre projet</h4>
                  <input type="text" placeholder="Nom du projet" />
                  <button type="submit" className="btn-eleven1 fw-500 tran3s d-block mt-20">
                    <Link to="/create_soc6">valider</Link>
                  </button>
                  <hr />
                  <p className="note">
                    En fonction du régime TVA que vous aurez choisi, vous pourrez récupérer les frais de TVA via votre future entreprise.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <style jsx>{`
        .order-section {
          padding: 50px 0 0px 0;
        }
       
        .btn-success {
          background-color: #28a745;
          color: #fff;
          border: none;
          padding: 10px 20px;
          font-size: 16px;
        }
        .section-title {
          font-size: 24px;
          font-weight: 600;
          margin: 20px 0;
        }
        .sub-title {
          font-size: 20px;
          font-weight: 400;
          margin: 10px 0;
        }
        .service-info {
          margin-top: 20px;
        }
        .service-title {
          font-size: 18px;
          font-weight: 600;
          margin-top: 20px;
        }
        .service-info ul {
          list-style: disc;
          padding-left: 20px;
        }
        .center-section {
          padding: 20px;
        }
        .btn-primary {
          background-color: #007bff;
          color: #fff;
          border: none;
          padding: 10px 20px;
          font-size: 16px;
          margin-bottom: 20px;
        }
        .pricing-info {
          margin-top: 20px;
        }
     
        .right-section {
          background: #fff;
          padding: 20px;
          border-radius: 5px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }
        .card-header {
          border-bottom: 1px solid #ddd;
          padding-bottom: 10px;
          margin-bottom: 10px;
        }
        .card-body {
          padding-top: 10px;
        }
        .note {
          font-size: 0.875rem;
          color: #666;
        }
        .card-header h3 {
          margin: 0;
        }
        .card-body input {
          width: 100%;
          padding: 10px;
          margin-top: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
        .card-body hr {
          margin: 20px 0;
        }
          .left-section {
  padding: 20px;
}
/* partie ajoute */ 
.btn-group {
  display: flex;
  gap: 450px; 
}

.section-title {
  margin-top: 20px;
  margin-bottom: 10px;
}

.sub-title {
  margin-bottom: 20px;
}

.pricing-table {
  width: 100%;
  margin-bottom: 20px;
}

.service-title {
  text-align: left;
  padding: 10px 15px;
}

.pricing-item {
  text-align: right;
  padding: 10px 15px;
}

ul {
  padding-left: 20px;
}

ul li {
  margin-bottom: 10px;
}

          
      `}</style>
    </>
  );
};

export default CandidateV3Area;
