'use client'
import React,{useState} from 'react';
import EmployAside from '../../aside';
import DevisCreate from './devisCreate';

const EmployDashboardProfilePage = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (   

    <div className='main-page-wrapper'>
      {/* aside start */}
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar}/>
      {/* aside end  */}

      {/* profile area start */}
      <DevisCreate setIsOpenSidebar={setIsOpenSidebar}/>
      {/* profile area end */}
    </div>    
  );
};

export default EmployDashboardProfilePage;