import React, { useState } from 'react';
import DashboardHeader from '../../header';
import { Document, Page } from '@react-pdf/renderer';

const DocumentSigner = ({ setIsOpenSidebar }) => {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const documents = [
    { name: 'nom pièce 1', type: 'pdf', url: '', signed: true },
    { name: 'nom pièce 2', type: 'pdf', url: '', signed: false }
  ];

  const handleDocumentClick = (doc) => {
    if (doc.signed) {
      setSelectedDocument(doc);
      setIsDrawerOpen(true);
    } else {
      alert("Le document n'est pas signé et ne peut pas être consulté.");
    }
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedDocument(null);
  };

  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        <h4 className="main-title h2-title text-center">
          Documents à signer pour <br /> nom-projet
        </h4>
        <div className="bg-white card-box border-20">
          <div className="table-responsive mt-5 mb-5" id="liste-table">
            <table className="table job-alert-table">
              <tbody>
                {documents.map((doc, index) => (
                  <tr key={index}>
                    <td className="text-left p-2">{doc.name}</td>
                    <td className="text-center p-2">
                      {doc.signed ? (
                        <i className="bi bi-check-circle-fill text-success"></i>
                      ) : (
                        <i className="bi bi-x-circle-fill text-danger"></i>
                      )}
                    </td>
                    <td className="text-center p-2">
                      <i
                        className={`fas fa-file-alt ${doc.signed ? 'text-success' : 'text-danger'} fs-4`}
                        onClick={() => handleDocumentClick(doc)}
                        style={{ cursor: 'pointer' }}
                      ></i>
                    </td>
                    <td className="text-center p-2">
                      {doc.signed ? 'Signé' : 'Non signé'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {isDrawerOpen && (
          <div className="drawer-overlay" onClick={closeDrawer}>
            <div className="drawer-content" onClick={(e) => e.stopPropagation()}>
              <span className="close-icon" onClick={closeDrawer}>
                &times;
              </span>
              {selectedDocument && selectedDocument.signed && (
                <div className="document-viewer">
                  {selectedDocument.url ? (
                    <Document file={selectedDocument.url}>
                      <Page pageNumber={1} />
                    </Document>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                    <img
                        src="./assets/images/logo/logo-pharos.png"
                        alt="Document Placeholder"
                        style={{ width: '50%', height: 'auto' }}
                    />
                </div>
                
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <style jsx>{`
        .dashboard-body .card-box {
          padding: 30px 30px 15px 30px;
        }
        .table-container {
          width: 100%;
        }
        .drawer-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: flex-end;
          z-index: 1000;
        }
        .drawer-content {
          background-color: white;
          width: 400px;
          height: 100%;
          padding: 30px;
          position: relative;
          box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
          animation: slideIn 0.3s forwards;
        }
        .close-icon {
          position: absolute;
          top: 10px;
          right: 15px;
          font-size: 30px;
          cursor: pointer;
        }
        @keyframes slideIn {
          from {
            transform: translateX(100%);
          }
          to {
            transform: translateX(0);
          }
        }
        .document-viewer {
          margin-top: 20px;
        }
      `}</style>
    </div>
  );
};

export default DocumentSigner;
