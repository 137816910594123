import React, { useState } from "react";
import { Link } from "react-router-dom";

const CandidateV3Area = ({ style_2 }) => {
    const [jobType, setJobType] = useState(style_2 ? "list" : "grid");
    const [textareaValue, setTextareaValue] = useState("");
    const [generatedText, setGeneratedText] = useState("");

    const handleTextareaChange = (event) => {
        setTextareaValue(event.target.value);
    };

    const handleGenerateClick = () => {
        setGeneratedText(`Text généré\n${textareaValue || 'Lorem ipsum...'}`);
    };

    return (
        <>
                    <div className="hero-banner-two position-relative">
                <div className="container">
                    <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop:'135px', paddingBottom:'30px' }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-8">              
                            <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">CRÉATION PACKAGE COMMERCIAL</h2>
                            </div>
                        </div>
                    </div>
                </div>                           
            </div>
            <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
                <div className="container">
                    <div className="text-center mb-4">
                        <div className="btn-group">
                            <Link to="/package-com8">
                                <button type="button" className="btn btn-success">
                                    {"< Précédent"}
                                </button>
                            </Link>
                            <span className="mx-3">11/15</span>
                        </div>
                    </div>
                    <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
                        <h4 className="fw-600">
                            Sélectionnez dans la liste déroulante des mots-clés concernant votre activité. Vous pouvez également ajouter des mots qui n’y figurent pas.
                        </h4>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 mb-4">
                            <select className="form-select" aria-label="Sélectionnez un mot-clé">
                                <option value="mot1">mot 1</option>
                                <option value="mot2">mot 2</option>
                                <option value="mot3">mot 3</option>
                            </select>
                        </div>
                        <div className="col-12 mb-4">
                            <textarea
                                className="form-control"
                                rows={3}
                                value={textareaValue}
                                onChange={handleTextareaChange}
                                placeholder="Ajoutez vos propres mots-clés ici"
                            ></textarea>
                        </div>
                        <div className="col-12">
                            <button
                                type="button"
                                className="btn-eleven fw-500 tran3s d-block mt-20"
                                onClick={handleGenerateClick}
                            >
                                Générez
                            </button>
                        </div>
                        {generatedText && (
                            <div className="col-12 mt-4">
                                <div className="generated-text-section p-4 border rounded">
                                    <h5 className="fw-600 mb-3">Text généré</h5>
                                    <p>{generatedText}</p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-12">

                        <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                            <Link to="/package-com9" className="d-block">
                                Validez
                            </Link>
                        </button>
                    </div>
                </div>
            </section>

        </>
    );
};

export default CandidateV3Area;
