import React from "react";

const CandidateItem = ({ item }) => {
  return (
    <div className="candidate-profile-card list-layout border-0 mb-20">
      <div className="d-flex">        
        <div className="right-side w-100">
          <div className="row gx-1 align-items-center">
            <div className="col-xl-4">
              <div className="position-relative">
                <h4 className="candidate-name mb-0">
                  <a href="#" className="tran3s">
                    {item.name}
                  </a>
                </h4>                                
              </div>
            </div>
            <div className="col-xl-3 col-md-4 col-sm-6">
              <div className="candidate-info">                
                <div>{item.salary}</div>
              </div>
            </div>
            <div className="col-xl-3 col-md-4 col-sm-6">
              <div className="candidate-info">                
                <div>{item.location} {item.salary_duration}</div>
              </div>
            </div>
            <div className="col-xl-2 col-md-2">
              <div className="d-flex justify-content-md-end align-items-center">
                <a href="#" className="d-block">
                  <img src="./assets/images/icon/pdf.svg" alt="PDF icon" width="40" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .candidate-profile-card.list-layout {
          padding: 20px 10px 25px;
        }
      `}</style>   
    </div>
  );
};

export default CandidateItem;
