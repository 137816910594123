'use client'
import React,{useState} from 'react';
import EmployAside from '../../aside';
import MesDemarches from './mes-demarches';

const MesDemarchesPage = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (    

    <div className='main-page-wrapper'>
      {/* aside start */}
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar}/>
      {/* aside end  */}

      {/* profile area start */}
      <MesDemarches setIsOpenSidebar={setIsOpenSidebar}/>
      {/* profile area end */}
    </div>    
  );
};

export default MesDemarchesPage;