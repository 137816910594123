import React, { useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CandidateV3Area = ({ style_2 }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">Choisissez la date et l’heure de rendez-vous</h2>
                {/* <p className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">Sélectionnez les thèmes que vous souhaitez aborder avec nos experts (plusieurs choix possibles)</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="candidates-profile  pt-90 lg-pt-70 pb-160 xl-pb-150 lg-pb-80" style={{ backgroundColor: '#FFF' }}>
        <div className="container">
          <div className="text-center mb-4">
            <div className="btn-group">
              <Link to="/rendez-vous5">
                <button type="button" className="btn btn-success">
                  {"< Précédent"}
                </button>
              </Link>
              <span className="mx-3">5/5</span>
              <Link to="/rendez-vous7">
                <button type="button" className="btn btn-success">
                  {"Suivant >"}
                </button>
              </Link>
            </div>
          </div>
          <div className="row mt-5">
            {/* Date and Time Picker Section */}
            <div className="col-12 text-center mt-4">
              <div className="mb-4">
                <h5>Choisissez une date et une heure</h5>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  showTimeSelect
                  dateFormat="dd/MM/yyyy HH:mm"
                  className="form-control custom-datepicker"
                />
              </div>
              <div className="text-center mt-4">
                <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                  <Link to="/rendez-vous7" className="text-white">
                    Valider →
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
