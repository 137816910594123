import React, { useState } from "react";
import { Link } from "react-router-dom";


const CandidateV3Area = ({ style_2 }) => {
  const [jobType, setJobType] = useState(style_2 ? "list" : "grid");

  const [selectedColor1, setSelectedColor1] = useState("");
  const [selectedColor2, setSelectedColor2] = useState("");

  const palette1 = ["#213A57", "#0B6477", "#14919B"]; // Example colors
  const palette2 = ["#F4E7FB", "#F3DCDC", "#F5BCBA"]; // Example colors

  const handleColor1Change = (color) => {
    setSelectedColor1(color);
  };

  const handleColor2Change = (color) => {
    setSelectedColor2(color);
  };

  return (
    <>
                <div className="hero-banner-two position-relative">
                <div className="container">
                    <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop:'135px', paddingBottom:'30px' }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-8">              
                            <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">CRÉATION PACKAGE COMMERCIAL</h2>
                            </div>
                        </div>
                    </div>
                </div>                           
            </div>
      <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
        <div className="container">
          <div className="text-center mb-4">
            <div className="btn-group">
              <Link to="/package-com9">
                <button type="button" className="btn btn-success">
                  {"< Précédent"}
                </button>
              </Link>
              <span className="mx-3">13/15</span>
            </div>
          </div>
          <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
            <h4 className="fw-600">Choissiez deux couleurs</h4>
          </div>
          <div className="row mt-5">
            <div className="col-md-6 mb-4">
              <div className="card p-3 text-center">
                <h5 className="mb-3">Palette 1 de couleurs</h5>
                <div className="d-flex justify-content-center">
                  {palette1.map((color) => (
                    <button
                      key={color}
                      className="color-swatch"
                      style={{
                        backgroundColor: color,
                        width: "50px",
                        height: "50px",
                        margin: "5px",
                        border: selectedColor1 === color ? "3px solid black" : "1px solid gray",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleColor1Change(color)}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="card p-3 text-center">
                <h5 className="mb-3">Palette 2 de couleurs</h5>
                <div className="d-flex justify-content-center">
                  {palette2.map((color) => (
                    <button
                      key={color}
                      className="color-swatch"
                      style={{
                        backgroundColor: color,
                        width: "50px",
                        height: "50px",
                        margin: "5px",
                        border: selectedColor2 === color ? "3px solid black" : "1px solid gray",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleColor2Change(color)}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12 text-center">

              <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/package-com11" className="d-block">
                  Validez
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default CandidateV3Area;
