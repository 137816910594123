import React from 'react';
import DashboardHeader from '../header';
import { Link } from 'react-router-dom';

const ProjetCreate = ({setIsOpenSidebar}) => {
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

        <h2 className="main-title h2-titel">Pharos Compta (Ma facturation) &gt; Projet nom</h2>
        
            <div className="bg-white card-box border-20">
                <div className="row justify-content-center">   
                <form >
                <h4 className="d-flex flex-column align-items-center mb-4">
                    <Link to='/ma-facturation' className="text-reset">
                        <u>Céer un nouveau projet</u>
                    </Link>
                </h4>

                <div className="row mb-4">
                            <div className="col-md-12">
                                <input type="text" className="form-control" id="" placeholder="Renseigner un nom de projet" />
                            </div>
                        </div>
                        <h4 className="d-flex flex-column align-items-center mb-4">ou</h4>
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <select className="form-control" id="" defaultValue="">
                                    <option value="" disabled hidden>Choisissez un projet Pharos WebPro ou Legal</option>
                                    <option value="projet1">Projet 1</option>
                                    <option value="projet2">Projet 2</option>
                                </select>
                            </div>
                        </div>
                        <div className='d-flex flex-column align-items-center mb-4'>
                           <div className="button-group d-inline-flex col-4  align-items-center justify-content-center">
                             <Link to="/ma-facturation-index" className="text-reset me-3 card_dash">Annuler</Link>          
                             <Link to="/ma-facturation-index" className="dash-btn-two tran3s me-3 card_dash">Ajouter</Link>          
                           </div>
                        </div>
                    </form>                                                         
                </div>                            
            </div>
      </div>
    </div>
  );
};

export default ProjetCreate;