import React from 'react';
import DashboardHeader from '../header';
import { Link } from 'react-router-dom';

const Facturation = ({setIsOpenSidebar}) => {
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

        <h2 className="main-title text-center h2-titel">Pharos Compta (Ma facturation) &gt; Projet nom</h2>
        
        <div className="bg-white card-box border-20">
            <div className="row justify-content-center">
                <h4 className="text-center">Information générale</h4>
                {/* Cadre pour le logo */}
                <div className="logo-container col-6 d-flex align-items-center justify-content-center">
                <div className="logo-frame">
                    <img src="./assets/images/logo/logo-pharos.png" alt="Exemple de logo" className="logo-image" />
                </div>
                </div>  
                <div className="button-group col-6 mt-4 d-flex align-items-center justify-content-center">
                    <Link to="/ma-facturation-information-generale" className="dash-btn-two tran3s me-3 card_dash ma_facturation_card text-center">
                        Modifier
                    </Link>
                    </div>
               
            </div>
        </div>


        {/* Section Factures */}
        <div className="bg-white card-box border-20 mt-3">
          <div className="row justify-content-center">
            <h4 className="text-center">Mes factures</h4>
            <div className="button-group col-6 d-flex align-items-center justify-content-center">
              <Link to="/ma-facturation-factures" className="dash-btn-two tran3s mt-4 me-3 card_dash ma_facturation_card">Historique des factures</Link>          
            </div>  
            <div className="button-group col-6 mt-4 d-flex align-items-center justify-content-center">
              <Link to="/ma-facturation-factures-create" className="dash-btn-two tran3s me-3 card_dash ma_facturation_card">Créer une facture</Link>          
            </div>               
          </div>
        </div>

        {/* Section Devis */}
        <div className="bg-white card-box border-20 mt-3">
          <div className="row justify-content-center">
            <h4 className="text-center">Mes devis</h4>
            <div className="button-group col-6 d-flex align-items-center justify-content-center">
              <Link to="/ma-facturation-devis" className="dash-btn-two tran3s mt-4 me-3 card_dash ma_facturation_card">Historique des devis</Link>          
            </div>  
            <div className="button-group col-6 mt-4 d-flex align-items-center justify-content-center">
              <Link to="/ma-facturation-devis-create" className="dash-btn-two tran3s me-3 card_dash ma_facturation_card">Créer un devis</Link>          
            </div>               
          </div>
        </div>

        {/* Section Achats */}
        <div className="bg-white card-box border-20 mt-3">
          <div className="row justify-content-center">
            <h4 className="text-center">Mes achats</h4>
            <div className="button-group col-6 d-flex align-items-center justify-content-center">
              <Link to="/ma-facturation-achats" className="dash-btn-two tran3s mt-4 me-3 card_dash ma_facturation_card">Historique des achats</Link>          
            </div>  
            <div className="button-group col-6 mt-4 d-flex align-items-center justify-content-center">
              <Link to="/ma-facturation-achats-create" className="dash-btn-two tran3s me-3 card_dash ma_facturation_card">Créer un achat</Link>          
            </div>               
          </div>
        </div>

        {/* Section Clients/Fournisseurs */}
        <div className="bg-white card-box border-20 mt-3">
          <div className="row justify-content-center">
            <h4 className="text-center">Mes clients / fournisseurs</h4>
            <div className="button-group col-6 d-flex align-items-center justify-content-center">
              <Link to="/ma-facturation-clients-fournisseurs" className="dash-btn-two tran3s mt-4 me-3 card_dash ma_facturation_card">Liste des clients / fournisseurs</Link>          
            </div>  
            <div className="button-group col-6 mt-4 d-flex align-items-center justify-content-center">
              <Link to="/ma-facturation-clients-fournisseurs" className="dash-btn-two tran3s me-3 card_dash ma_facturation_card">Ajouter un nouveau client / fournisseur</Link>          
            </div>               
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facturation;
