"use client";
import React from "react";
//import { CardItem } from "../candidate/dashboard-area";
import Calendar from './calendar'; 
import DashboardHeader from "../header";

// props type 
/* type IProps = {
  setIsOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>
} */

const Rdv = ({setIsOpenSidebar}) => {
  return (
    <div className="dashboard-body">
         <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

      <div className="position-relative">
        

        <h2 className="main-title mb-0 h2-titel">Prendre rdv avec nos experts</h2>       

        <div className="row d-flex">
          <div className="col-xl-12 col-lg-12 d-flex flex-column">
            <div className="user-activity-chart bg-white border-20 mt-30 h-100">
              <h4 className="dash-title-two">Calendrier</h4>
              <div className="p-4">                
                <Calendar />   
                <div className="d-flex justify-content-center mt-4">                  
                  <a href="#" className="dash-btn-two tran3s me-3">Valider</a>
                </div>                             
              </div>                            
            </div>
          </div>          
        </div>
      </div>
    </div>
  );
};

export default Rdv;
