import React from "react";
import { Link } from "react-router-dom"; // Import from react-router-dom
import { FaFileInvoice, FaFileAlt } from 'react-icons/fa';

const CandidateV3Area = ({ style_2 }) => {
  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">CRÉATION D’XXXXX</h2>
                {/* <p className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">sous-titre</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
        <div className="container">
          <div className="text-center mb-4">
            <h2 className="fw-600">
              Ajoutez d’autres services Pharos pour une commande plus complète
            </h2>
            <br /> <br />
            <div className="btn-group">
              <Link to="/create_soc1">
                <button type="button" className="btn btn-success">
                  {"< Précédent"}
                </button>
              </Link>
              <span className="mx-3">2/4</span>
            </div>
          </div>
          <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp mb-2">
              <h3 className="fw-600">
                Domiciliation avec Pharos Legal xx € HT
              </h3>
              <p className="fw-600 mt-4"> {/* Reduced margin-top from mt-3 to mt-2 */}
                Afin de ne pas utiliser votre propre adresse personnelle<br /> 
                pour la domiciliation de votre future société, Pharos Legal vous propose<br /> 
                des adresses partenaires pour plus de sécurité.
              </p>
            </div>

            <div className="row mb-2">
              <div className="col-12">
                <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-2"> {/* Reduced margin-top */}
                  <Link to="/create_soc3">Ajouter et poursuivre la commande → </Link>
                </button>
              </div>
              <div className="col-12" style={{ textAlign: 'center', marginTop: '5px' }}> 
                <Link to="/create_soc3" className="fw-500">
                  Poursuivre la commande →
                </Link>
              </div>
            </div>

        </div>
      </section>
      {/* <CandidateFilterModal /> */}
    </>
  );
};

export default CandidateV3Area;
