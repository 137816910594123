import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 


const CandidateV3Area = ({ style_2 }) => {
    const [jobType, setJobType] = useState(style_2 ? 'list' : 'grid');


    return (
        <>
                    <div className="hero-banner-two position-relative">
                <div className="container">
                    <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop:'135px', paddingBottom:'30px' }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-8">              
                            <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">CRÉATION PACKAGE COMMERCIAL</h2>
                            </div>
                        </div>
                    </div>
                </div>                           
            </div>
            <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
                <div className="container">
                    <div className="text-center mb-4">
                        <div className="btn-group">
                            <Link to="/package-com6" className="btn btn-success">
                                {"< Précédent"}
                            </Link>
                            <span className="mx-3">9/15</span>
                        </div>
                    </div>
                    <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
                        <h4 className="fw-600">Avez-vous des réseaux sociaux ?</h4>
                    </div>
                    <div className="col-12 mb-4">
                        <div className="input-group-meta position-relative mb-25">
                            <label htmlFor="instagram">Instagram</label>
                            <input
                                type="text"
                                id="instagram"
                                placeholder="lien Instagram"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-12 mb-4">
                        <div className="input-group-meta position-relative mb-25">
                            <label htmlFor="facebook">Facebook</label>
                            <input
                                type="text"
                                id="facebook"
                                placeholder="lien Facebook"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-12 mb-4">
                        <div className="input-group-meta position-relative mb-25">
                            <label htmlFor="snapchat">Snapchat</label>
                            <input
                                type="text"
                                id="snapchat"
                                placeholder="lien Snapchat"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-12 mb-4">
                        <div className="input-group-meta position-relative mb-25">
                            <label htmlFor="tiktok">Tiktok</label>
                            <input
                                type="text"
                                id="tiktok"
                                placeholder="lien Tiktok"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-12 mb-4">
                        <div className="input-group-meta position-relative mb-25">
                            <label htmlFor="youtube">YouTube</label>
                            <input
                                type="text"
                                id="youtube"
                                placeholder="lien YouTube"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">


                            <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                                <Link to="/package-com8" className="d-block">
                                    Validez
                                </Link>
                            </button>

                            <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                                <Link to="/package-com8" className="d-block">
                                    Passez cette étape
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default CandidateV3Area;
