import React, { useState } from "react";
import { Link } from "react-router-dom";


const CandidateV3Area = ({ style_2 }) => {
  const [jobType, setJobType] = useState(style_2 ? "list" : "grid");


  // Example image path (adjust this path to match your actual file structure)
  const siteModelImage = "/assets/images/blog/njt.png";

  return (
    <>
                    <div className="hero-banner-two position-relative">
                <div className="container">
                    <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop:'135px', paddingBottom:'30px' }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-8">              
                            <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">CRÉATION PACKAGE COMMERCIAL</h2>
                            </div>
                        </div>
                    </div>
                </div>                           
            </div>
      <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
        <div className="container">
          <div className="text-center mb-4">
            <div className="btn-group">
              <Link to="/package-com11">
                <button type="button" className="btn btn-success">
                  {"< Précédent"}
                </button>
              </Link>
              <span className="mx-3">15/15</span>
            </div>
          </div>
          <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
            <h4 className="fw-600">Visualisez votre site vitrine</h4>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <div className="card mb-4">
                <img src={siteModelImage} className="card-img-top" alt="Modèle du site" />
                <div className="card-body">
                  <h5 className="card-title text-center">Modèle du site</h5>
                </div>
              </div>
            </div>
            <div className="col-12">
              <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/package-com1" className="d-block">
                Recommencez
                </Link>
              </button>
              <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/ma-communication" className="d-block">
                Enregistrez les modifications
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default CandidateV3Area;
