'use client'
import React,{useState} from 'react';
import EmployAside from '../../aside';
import CreateEchance from './createEchance';

const ConditionGenal = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (
    
    <div className='main-page-wrapper'>
      {/* aside start */}
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar}/>
      {/* aside end  */}

      {/* profile area start */}
      <CreateEchance setIsOpenSidebar={setIsOpenSidebar}/>
      {/* profile area end */}
    </div>   
  );
};

export default ConditionGenal;