import React, { useState } from "react";
import { Link } from 'react-router-dom';
import DashboardHeader from "../header";
import CandidateItem from "./candidate-item";

const candidate_data = [
  {
    id: 1,
    img: "./assets/images/candidates/img_01.jpg",
    name: 'Projet 1',
    post: 'Graphic Designer',
    salary: '01/08/2024',
    location: '10000',
    salary_duration: '€',
  },
  {
    id: 2,
    img: "./assets/images/candidates/img_02.jpg",
    name: 'Projet 2',
    post: 'Javascript Developer',
    salary: '05/08/2024',
    location: '15100',
    salary_duration: '€',
  },
  {
    id: 3,
    img: "./assets/images/candidates/img_03.jpg",
    name: 'Projet 3',
    post: 'UI/UX Designer',
    salary: '15/07/2024',
    location: '25100',
    salary_duration: '€',
  },
];

const MesAchatsIndex = ({ setIsOpenSidebar }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleImageClick = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

        <div className="d-flex align-items-center justify-content-between mb-40 lg-mb-30">
          <h2 className="main-title m0 h2-titel">Mes achats</h2>
        </div>

        <div className="wrapper">
          {candidate_data.map((item) => (
            <div key={item.id} onClick={handleImageClick}>
              <CandidateItem item={item} />
            </div>
          ))}
        </div>

        {/* Simple Drawer implementation */}
        {isDrawerOpen && (
          <div className="drawer">
            <div className="drawer-content">
              <img
                src="./assets/images/logo/logo-pharos.png"
                alt="Document Placeholder"
                style={{ width: '100%', height: 'auto' }}
              />
            </div>
            <div className="drawer-overlay" onClick={handleCloseDrawer}></div>
          </div>
        )}

        <div className="dash-pagination d-flex justify-content-end mt-30">
          <ul className="style-none d-flex align-items-center">
            <li><Link to="#" className="active">1</Link></li>
            <li><Link to="#">2</Link></li>
            <li><Link to="#">3</Link></li>
            <li>..</li>
            <li><Link to="#">7</Link></li>
            <li><Link to="#"><i className="bi bi-chevron-right"></i></Link></li>
          </ul>
        </div>
      </div>

      {/* Drawer Styles */}
      <style jsx>{`
  .drawer {
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    background: white; /* Plain white background */
    z-index: 1000;
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
  }
  .drawer-content {
    padding: 20px;
  }
  .drawer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* Slightly darker overlay */
    z-index: 999;
  }
`}</style>

    </div>
  );
};

export default MesAchatsIndex;
