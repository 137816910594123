import React,{useState} from 'react';
import EmployAside from '../aside';
import NouveauEmail from './nouveau-email';

const BoitesMessage = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (    
    <div className='main-page-wrapper'>
      {/* aside start */}
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar}/>
      {/* aside end  */}

      {/* messages area start */}
      <NouveauEmail setIsOpenSidebar={setIsOpenSidebar}/>
      {/* messages area end */}
    </div>    
  );
};

export default BoitesMessage;