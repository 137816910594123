import React from "react";
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";

// Placeholder for CandidateFilterModal and blog_data
const CandidateFilterModal = () => (
    <div>
        {/* Modal content */}
        <p>Candidate Filter Modal</p>
    </div>
);

const blog_data = [
    {
        blog: "blog-two",
        title: "Sample Blog",
        content: "This is a sample blog post."
    }
];

const CandidateV3Area = ({ style_2 }) => {
    const blog_items = blog_data.filter((b) => b.blog === "blog-two");

    return (
        <>

<div className="hero-banner-two position-relative">
                <div className="container">
                    <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">Choisir son statut</h2>
                                {/* <p className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">sous-titre</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blog-section-two pt-20 xl-pt-30 lg-pt-100 pb-150 xl-pb-130 lg-pb-80">
                <div className="container">
                    <div className="text-center mb-4">
                        <div className="btn-group">
                            <Link to="/activite-statut-p1">
                                <button type="button" className="btn btn-success">
                                    {"< Précédent"}
                                </button>
                            </Link>
                        </div>
                        <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
                            <h2 className="fw-600">SELAS ou SELARL</h2>
                        </div>
                    </div>

                    <div className="row mt-5 justify-content-center">
                        <div className="col-md-8">
                            <div className="info-card text-center">
                                <div className="info-icon">
                                    <FaCheckCircle />
                                </div>
                                <h3>Informations complémentaires</h3>
                                <p>Icon</p>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-12">
                            <Link to="/" className="d-block">
                                <button type="button" className="btn-eleven fw-500 tran3s d-block mt-20">
                                    Créer ma SELAS ou SELARL
                                </button>
                            </Link>
                        </div>
                    </div>
                    <br />
                    <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
                        <p className="fw-600">texte résumé du statut</p>
                    </div>

                    <div className="row mt-5">
                        <div className="col-12">
                            <Link to="/" className="d-block">
                                <button type="button" className="btn-eleven fw-500 tran3s d-block mt-20">
                                    En savoir plus
                                </button>
                            </Link>
                            <Link to="/" className="d-block">
                                <button type="button" className="btn-eleven fw-500 tran3s d-block mt-20">
                                    Faire une autre simulation
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <style jsx>{`
        .info-card {
          background: #fff;
          padding: 40px;
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          text-align: center;
        }
        .info-icon {
          font-size: 70px;
          color: #28a745;
          margin-bottom: 20px;
        }
        .info-card h3 {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .info-card p {
          font-size: 20px;
          font-weight: 500;
        }
      `}</style>
        </>
    );
};

export default CandidateV3Area;
