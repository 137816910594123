import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import {Link} from 'react-router-dom';

const AddAssocie = ({ show, handleClose ,handleAddAssocie}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un associé</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
        <div className="row">
   
   
    <div className="col-md-6">
      <h4>Associé 1</h4>
      <div className="mb-4">
        <input type="text" className="form-control" id="firstNameInput" placeholder="Prénom" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="lastNameInput" placeholder="Nom" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="birthPlaceInput" placeholder="Lieu de naissance" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="birthDateInput" placeholder="Date de naissance" />
      </div>
      <div className="mb-4">
        <input type="email" className="form-control" id="emailInput" placeholder="Email" />
      </div>
      <div className="mb-4">
        <input type="tel" className="form-control" id="phoneInput" placeholder="Tel" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="addressInput" placeholder="Adresse" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="personalSituationInput" placeholder="Situation personnelle" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="professionalSituationInput" placeholder="Situation professionnelle" />
      </div>
    </div>

    {/* Conjoint(e) */}
    <div className="col-md-6">
      <h4>Conjoint(e)</h4>
      <div className="mb-4">
        <input type="text" className="form-control" id="spouseFirstNameInput" placeholder="Prénom" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="spouseLastNameInput" placeholder="Nom" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="spouseMaidenNameInput" placeholder="Nom de jeune fille" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="spouseBirthPlaceInput" placeholder="Lieu de naissance" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="spouseBirthDateInput" placeholder="Date de naissance" />
      </div>
    </div>
    
    
  </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Link to="#"
                      style={{
                        backgroundColor: 'gray',
                        color: 'white',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        textDecoration: 'none',
                        transition: 'all 0.3s ease'
                      }}onClick={handleClose}> Annuler</Link>
       
        
        <Link
          to="#"
                      style={{
                        backgroundColor: '#19AF78',
                        color: 'white',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        textDecoration: 'none',
                        transition: 'all 0.3s ease'
                      }}
                      onClick={handleAddAssocie}
                    >
                       Ajouter un associé
                    </Link>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAssocie;
