import React from 'react';
import DashboardHeader from '../header';
import { Link } from 'react-router-dom';

const FacturationIndex = ({setIsOpenSidebar}) => {
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

        <h2 className="main-title h2-titel">Pharos Compta (Ma facturation) &gt; Projet nom</h2>
        
        <div className="bg-white card-box border-20">
          <div className="row justify-content-center">
             <div className="col-12">                   
                    <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                        <Link to="/ma-facturation">projet 1</Link>
                        </button>
                        <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                         <Link to="/creer-nouveau-projet">projet 2</Link>
                        </button>
                        <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                         <Link to="/creer-nouveau-projet">Créer un nouveau projet</Link>
                        </button>
                        </div>                                                     
                 </div>                         
            </div>
      </div>
    </div>
  );
};

export default FacturationIndex;