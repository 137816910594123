import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function ConditionsModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* Trigger Button */}
      <div className="col-md-6" onClick={handleShow} style={{ cursor: 'pointer' }}>
        <h5>conditions générales</h5>
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Conditions générales</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Form */}
          <form>
            <h4 className="d-flex flex-column align-items-center mb-4">
              <Link to='/ma-facturation' className="text-reset">
                <u>Gérez vos échances et paiements</u>
              </Link>
            </h4>

            <div className='row'>
              <div className='col-md-5'>
                <h5 className='mb-4'><u>Conditions</u></h5>
                <div className="form-check mb-2">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    COMPTANT
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                    A RECEPTION
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                  <label className="form-check-label" htmlFor="flexRadioDefault3">
                    A 30 JOURS FIN DE MOIS
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                  <label className="form-check-label" htmlFor="flexRadioDefault4">
                    A 45 JOURS FIN DE MOIS
                  </label>
                </div>
              </div>

              <div className='col-md-7'>
                <h5><u>Text apparaissant sur la facture</u></h5>
                <div>
                  <textarea className="col-md-12 invoice-text-box" rows="5" defaultValue="• 100 % soit xxxx € à payer le : 30/11/2024 (45 jours fin de mois).&#10;(PS: zone de texte qui reste modifiable à la main)">
                  </textarea>
                </div>
                <div className="button-group d-inline-flex align-items-center justify-content-center mt-4">
                  <Link to="/ma-facturation-factures-create" className="text-reset me-3 card_dash">Annuler</Link>
                  <Link to="/ma-facturation-factures-create" className="dash-btn-two tran3s me-3 card_dash">Ajouter</Link>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <style>{`
                 .invoice-text-box {
                    width: 100%;
                    border: 1px solid #ccc;
                    padding: 10px;
                    margin-top: 15px;
                    background-color: #f9f9f9;
                    border-radius: 5px;
                    resize: none; /* Optional: to prevent resizing */
                }
            `}</style>
    </>
  );
}

export default ConditionsModal;
