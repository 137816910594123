import React from "react";
import { Link } from "react-router-dom"; // Use react-router-dom for routing
import { FaDesktop, FaGlobe, FaEnvelope, FaFileAlt } from 'react-icons/fa'; // Importing appropriate icons

const CandidateV3Area = ({ style_2 }) => {
  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">CRÉATION D’XXXXX</h2>
                {/* <p className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">sous-titre</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
        <div className="container">
          <div className="text-center mb-3">
            <h2 className="fw-600">
              Ajoutez d’autres services Pharos pour une commande plus complète
            </h2>
            <br /> <br />
            <div className="btn-group">
              <Link to="/create_soc3">
                <button type="button" className="btn btn-success">
                  {"< Précédent"}
                </button>
              </Link>
              <span className="mx-3">4/4</span>
            </div>
          </div>
          <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp mb-3">
            <h3 className="fw-600">
              Pharos WebPro <br /> 49,90 € HT / mois
            </h3>
            <br />
            <p className="fw-600">
              Pharos WebPro vous crée en quelques minutes tous les outils<br /> digitaux essentiels pour une présence professionnelle sur internet
            </p>
           
            <div className="d-flex flex-column align-items-center justify-content-center mt-4">
            <div className="d-flex justify-content-center align-items-center ">
              <div className="feature-list" style={{ width: '100%', maxWidth: '600px' }}>
                <div className="d-flex align-items-center mb-3">
                  <FaDesktop size={40} className="text-success me-3" />
                  <div>
                    <h4 className="mb-0">Site Web vitrine</h4>
                  </div>
                </div>

                <div className="d-flex align-items-center mb-3">
                  <FaGlobe size={40} className="text-success me-3" />
                  <div>
                  <h4 className="mb-0">Un nom de domaine</h4>
                  <p>exemple https://ma-societe.fr</p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <FaEnvelope size={40} className="text-success me-3" />
                  <div>
                  <h4 className="mb-0">Une adresse et boite mail professionnelle</h4>
                  <p>exemple contact@ma-societe.fr</p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <FaFileAlt size={40} className="text-success me-3" />
                  <div>
                  <h4 className="mb-0">Un gestionnaire électronique de documents (GED) pour stocker en ligne les documents
                  </h4>
                  </div>
                </div>
              </div>
              
        </div>
        </div>

         
  
  {/* Centralizing the button */}
  <div className="row mt-5 justify-content-center">
    <div className="col-12 text-center">
      <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
        <Link to="/create_soc5">Ajouter et poursuivre la commande  → </Link>
      </button>
    </div>
  </div>
</div>

        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
