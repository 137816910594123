import React, { useState } from "react";
import { Link } from "react-router-dom";


const CandidateV3Area = ({ style_2 }) => {
  const [jobType, setJobType] = useState(style_2 ? "list" : "grid");
  const [selectedFont, setSelectedFont] = useState("Arial");

  const handleFontChange = (event) => {
    setSelectedFont(event.target.value);
  };

  return (
    <>
                <div className="hero-banner-two position-relative">
                <div className="container">
                    <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop:'135px', paddingBottom:'30px' }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-8">              
                            <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">CRÉATION PACKAGE COMMERCIAL</h2>
                            </div>
                        </div>
                    </div>
                </div>                           
            </div>
      <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
        <div className="container">
          <div className="text-center mb-4">
            <div className="btn-group">
              <Link to="/package-com8">
                <button type="button" className="btn btn-success">
                  {"< Précédent"}
                </button>
              </Link>
              <span className="mx-3">12/15</span>
            </div>
          </div>
          <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
            <h4 className="fw-600">Choisissez la police de votre site</h4>
          </div>
          <div className="row mt-5">
            <div className="col-12 mb-4">
              <label htmlFor="font-select" className="form-label">Liste déroulante de polices</label>
              <select
                id="font-select"
                className="form-select"
                value={selectedFont}
                onChange={handleFontChange}
              >
                <option value="Arial">Arial</option>
                <option value="Courier New">Courier New</option>
                <option value="Georgia">Georgia</option>
                <option value="Times New Roman">Times New Roman</option>
                <option value="Verdana">Verdana</option>
              </select>
            </div>
            <div className="col-12 mt-4">
              <div className="example-text-section p-4 border rounded" style={{ fontFamily: selectedFont }}>
                <h5 className="fw-600 mb-3">Un exemple de texte avec la police appliquée</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae scelerisque enim ligula venenatis dolor.
                </p>
              </div>
            </div>
            <div className="col-12 mt-4">
              <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/package-com10" className="d-block">
                  Validez
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
