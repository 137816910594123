import React from "react";
import { Link } from "react-router-dom";

const LoginPage = () => {
  return (
    <div className="login-page">
      {/* Header Section */}
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">Login</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Login Form Section */}
      <div className="container">
        <div className="user-data-form">
          <div className="text-center">
            <h2>Salut, Bon Retour !</h2>
            <p>
              Vous n'avez toujours pas de compte?{" "}
              <Link to="/register">Inscrivez-vous</Link>
            </p>
          </div>
          <div className="form-wrapper m-auto">
            <form action="#" className="mt-10">
              <div className="row">
                <div className="col-12">
                  <div className="input-group-meta position-relative mb-25">
                    <label>Email*</label>
                    <input
                      type="email"
                      placeholder="votre-email@example.com"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="input-group-meta position-relative mb-20">
                    <label>Mot de passe*</label>
                    <input
                      type="password"
                      placeholder="Entrez le mot de passe"
                      className="pass_log_id"
                    />
                    <span className="placeholder_icon">
                      <span className="passVicon">
                        <img
                          src="/assets/images/icon/icon_60.svg"
                          alt=""
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="agreement-checkbox d-flex justify-content-between align-items-center">
                    <div>
                      <input type="checkbox" id="remember" />
                      <label htmlFor="remember">Garder ma session active</label>
                    </div>
                    <Link to="#">Mot de passe oublié?</Link>
                  </div>
                </div>
                <div className="col-12">
                  <button className="btn-eleven1 fw-500 tran3s d-block mt-20">
                    Connexion
                  </button>
                </div>
              </div>
            </form>
            <div className="d-flex align-items-center mt-30 mb-10">
              <div className="line"></div>
              <span className="pe-3 ps-3" style={{ whiteSpace: 'nowrap' }}>Ou se connecter avec</span>
              <div className="line"></div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Link
                  to="#"
                  className="social-use-btn d-flex align-items-center justify-content-center tran3s w-100 mt-10"
                >
                  <img
                    src="/assets/images/icon/google.png"
                    alt="Google Icon"
                  />
                  <span className="ps-2">Connexion avec Google</span>
                </Link>
              </div>
              <div className="col-md-6">
                <Link
                  to="#"
                  className="social-use-btn d-flex align-items-center justify-content-center tran3s w-100 mt-10"
                >
                  <img
                    src="/assets/images/icon/microsoft.png"
                    alt="Facebook Icon"
                  />
                  <span className="ps-2">Connexion avec Mirosoft</span>
                </Link>
              </div>
            </div>
            <p className="text-center mt-10">
              Vous n'avez pas de compte?{" "}
              <Link to="/register" className="fw-500">
                Inscrivez-vous
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
