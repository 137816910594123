import React, { useState } from "react";
import { Link } from "react-router-dom";
// import CandidateFilterModal from "../common/popup/candidate-filter-modal";

// Import your icon components or use icon libraries
import { FaCalendarDay, FaCommentDots, FaQuestionCircle, FaCheckCircle } from 'react-icons/fa';

const CandidateV3Area = ({ style_2 }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (option) => {
    setSelectedOptions((prev) =>
      prev.includes(option) ? prev.filter((item) => item !== option) : [...prev, option]
    );
  };

  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">Formulez vos questions</h2>
                <p className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">Cela permettra aux juristes de préparer au mieux votre entretien.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="candidates-profile  pt-90 lg-pt-70 pb-160 xl-pb-150 lg-pb-80" style={{ backgroundColor: '#FFF' }}>
        <div className="container">
          <div className="text-center mb-4">
            <div className="btn-group">
              <Link to="/rendez-vous2">
                <button type="button" className="btn btn-success">
                  {"< Précédent"}
                </button>
              </Link>
              <span className="mx-3">3/5</span>
              <Link to="/rendez-vous5">
                <button type="button" className="btn btn-success">
                  {"Suivant >"}
                </button>
              </Link>
            </div>
          </div>
          <div className="row mt-5">
            {/* Textarea Section */}
            <div className="col-12 mb-4">
              <div className="text-center">
                <textarea
                  className="form-control"
                  rows={4}
                  placeholder="Entrez vos commentaires ici"
                />
              </div>
            </div>
            <div className="col-12 text-center mt-4">
              <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                <Link to="/rendez-vous5" className="text-white">
                  Continuer → 
                </Link>
              </button>
            </div>

          </div>
        </div>
      </section>

      {/* <CandidateFilterModal /> */}
    </>
  );
};

export default CandidateV3Area;
