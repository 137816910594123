import React, { useState } from "react";
import { Link } from "react-router-dom"; // Using react-router-dom for navigation

const CandidateV3Area = ({ style_2 }) => {
    const [jobType, setJobType] = useState(style_2 ? "list" : "grid");

    return (
        <>
            <div className="hero-banner-two position-relative">
                <div className="container">
                    <div
                        className="position-relative text-center pb-80 lg-pb-60"
                        style={{ paddingTop: "135px", paddingBottom: "30px" }}
                    >
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-md-8">
                                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">
                                    CRÉATION PACKAGE COMMERCIAL
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blog-section-two pt-0 xl-pt-5 lg-pt-5 pb-30 xl-pb-30 lg-pb-30">
                <div className="container">
                    <div className="text-center mb-4">
                        <div className="btn-group">
                            <Link to="/package-com5" className="btn btn-success">
                                {"< Précédent"}
                            </Link>
                            <span className="mx-3">8/15</span>
                        </div>
                    </div>
                    <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
                        <h4 className="fw-600">Remplissez les informations générales</h4>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 mb-4">
                            <div className="input-group-meta position-relative mb-25">
                                <label htmlFor="address">Adresse si vous avez une boutique/magasin</label>
                                <input
                                    type="text"
                                    id="address"
                                    name="address"
                                    placeholder="Choisissez une Adresse"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-8 mb-4">
                            <div className="input-group-meta position-relative mb-25">
                                <label htmlFor="phone">Numéro de téléphone</label>
                                <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    placeholder="Entrez un Numéro de téléphone"
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="col-12 col-md-8 mb-4">
                            <h5 className="text-center">Horaire d’ouverture</h5>
                            <div
                                className="horaires-wrapper border p-3 mx-auto"
                                style={{ borderRadius: "10px", maxWidth: "100%" }}
                            >
                                <div className="row mb-2">
                                    <div className="col"></div> {/* Empty for labels on the left */}
                                    <div className="col text-center">Début matinée</div>
                                    <div className="col text-center">Fin matinée</div>
                                    <div className="col text-center">Début après-midi</div>
                                    <div className="col text-center">Fin après-midi</div>
                                </div>
                                {["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"].map(
                                    (day) => (
                                        <div className="row mb-2 align-items-center" key={day}>
                                            <div className="col text-center" style={{ width: "100px" }}>
                                                {day}
                                            </div>
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="xxxxxx"
                                                />
                                            </div>
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="xxxxxx"
                                                />
                                            </div>
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="xxxxxx"
                                                />
                                            </div>
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="xxxxxx"
                                                />
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-8 mb-4">
                            <div className="input-group-meta position-relative mb-25">
                                <label htmlFor="store-image">Chargez une image de votre boutique/magasin</label>
                                <input
                                    type="file"
                                    id="store-image"
                                    name="store-image"
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center mt-5">
                        <div className="col-12 col-md-8 text-center">
                            <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                                <Link to="/package-com7" className="d-block">
                                    Validez
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CandidateV3Area;
