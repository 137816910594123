import React from 'react';
import JobAlertItem from './mes-demarche-item';
import DashboardHeader from '../../header';
import { Link } from 'react-router-dom';


const MesDemarches = ({setIsOpenSidebar}) => {
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        <h2 className="main-title text-center h2-titel">Mes démarches</h2>

        <div className="bg-white card-box border-20">
          <div className="table-responsive">
            <table className="table job-alert-table">
              <thead>
                <tr>
                  <th scope="col" className='text-center'>projet</th>
                  <th scope="col" className='text-center'>statut </th>
                  {/* <th scope="col">Job</th>
                  <th scope="col">Time</th>
                  <th scope="col">Actions</th> */}
                </tr>
              </thead>
              <tbody className="border-0">

                <JobAlertItem title='Démarche société' location='<nom-projet>' duration='A compléter' category='A compléter' found='2' time='Weekly'  ></JobAlertItem>         

                <JobAlertItem title='Démarche société' location='<nom-projet>' duration='Part-Time' category='Rejeté' found='13' time='Monthly' />

                <JobAlertItem title='Démarche société' location='<nom-projet>' duration='Validé' category='Validé' found='7' time='Daily' />            
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center mt-4">                  
            <Link to="/statut-juridique" className="dash-btn-two tran3s me-3">Créer une nouvelle société</Link>
        </div> 
        </div>


        <div className="dash-pagination d-flex justify-content-end mt-30">
          <ul className="style-none d-flex align-items-center">
            <li><a href="#" className="active">1</a></li>
            <li><a href="#">2</a></li>
            <li><a href="#">3</a></li>
            <li>..</li>
            <li><a href="#">7</a></li>
            <li><a href="#"><i className="bi bi-chevron-right"></i></a></li>
          </ul>
        </div>        
      </div>      
    </div>
  );
};

export default MesDemarches;