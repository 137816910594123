"use client";
import React, { useState } from "react";
import { FaUserAlt, FaUserFriends } from 'react-icons/fa';
import {Link} from 'react-router-dom';

// Mock data for blog items
const blog_data = [
    { blog: "blog-two", title: "Blog Item 1", content: "Content of blog item 1" },
    { blog: "blog-two", title: "Blog Item 2", content: "Content of blog item 2" }
];

// Mock BlogItemTwo component
const BlogItemTwo = ({ title, content }) => (
    <div className="blog-item">
        <h3>{title}</h3>
        <p>{content}</p>
    </div>
);

// Mock CandidateFilterModal component
const CandidateFilterModal = () => (
    <div className="candidate-filter-modal">
        {/* Modal content here */}
    </div>
);

const CandidateV3Area = ({ style_2 }) => {
    const [jobType, setJobType] = useState(style_2 ? "list" : "grid");
    const blog_items = blog_data.filter((b) => b.blog === "blog-two");

    return (
        <>

            <div className="hero-banner-two position-relative">
                <div className="container">
                    <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">Choisir son statut</h2>
                                {/* <p className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">sous-titre</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blog-section-two pt-20 xl-pt-30 lg-pt-100 pb-150 xl-pb-130 lg-pb-80">
                <div className="container">
                    <div className="text-center mb-5">
                        <div className="btn-group">
                            <button type="button" className="btn btn-success" onClick={() => window.location.href = "/"}>
                                {"< Précédent"}
                            </button>
                            <span className="mx-3">1/5</span>
                            <button type="button" className="btn btn-success" onClick={() => window.location.href = "activite-statut-s"}>
                                {"Suivant >"}
                            </button>
                        </div>
                        <div className="title-one text-center mb-3 lg-mb-10 wow fadeInUp">
                            <h2 className="fw-600 mt-5">Lancez-vous votre activité : </h2>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                        <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                        <FaUserAlt className="me-2" /> <Link to="/activite-statut-s">Seul</Link>
                        </button>
                        <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                        <FaUserFriends className="me-2" /> <Link to="/activite-statut-p">A plusieurs</Link>
                        </button>
                        </div>
                    

</div>


                </div>
            </section>
            <CandidateFilterModal />
        </>
    );
};

export default CandidateV3Area;
