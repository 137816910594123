"use client"
import React, {useState} from 'react';
import DashboardHeader from '../../header';
import { Modal, Button } from 'react-bootstrap'; 
import {Link,useNavigate } from 'react-router-dom';

const Factures = ({setIsOpenSidebar}) => {  
  
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true); 
    const [isOpenSearchModal, setIsOpenSearchModal] = useState(false); 
    const [selectedOption, setSelectedOption] = useState(''); 
  const [ShowSearchModal,setShowModal]=useState('');
  const handleCloseSearcheModal = () => setShowModal(false);
  const handleShowSearchModal = () => setShowModal(true);
    const handleCloseModalSearch = () => setShowModal(false); 
  
    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value); // Update selected radio option
    };
  
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
  
    const [selected, setSelected] = useState("");
  
    const handleSelect = (value) => {
      setSelected(value); // Update selected value
      setIsOpen(false); // Close the dropdown after selection
    };
  
    const [data, setData] = useState([
      { id: 1, designation: 'Mission data', quantity: 10, price: 100, tva: 20, amount: 1000 },
      { id: 2, designation: 'Mission data', quantity: 10, price: 100, tva: 20, amount: 1000 }
    ]);
  
    const handleDelete = (id) => {
      const updatedData = data.filter(item => item.id !== id);
      setData(updatedData);
    };
    const handleAddClick = () => {
      navigate('/ma-facturation-factures-rechercher-societe'); 
    };

    // 
    const [showConditionGeneral, setShowConditionGeneral] = useState(false);

    const handleCloseConditionGeneral = () => setShowConditionGeneral(false);
    const handleShowConditionGeneral = () => setShowConditionGeneral(true);
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

        <h2 className="main-title h2-titel">Ma facturation &gt; Projet nom &gt; Créer une facture</h2>
        <div className="bg-white card-box border-20 mb-20">
            <div className="row mb-20">
                <div className="col-md-6">
                    <h5>Logo + info ma société</h5>
                </div>
                
                <div className="col-md-6">
        <input type="text" className="form-control mb-3" placeholder="Titre" />
        {/* Trigger to open modal */}
        <h5>
          <Link to="#" onClick={handleShow} className="text-reset">Info société client</Link>
        </h5>
      </div>
       </div>
            <hr />
            <div className='row mt-3'>
                <div className="col-md-6">
                    
                    <input type="date" className="form-control mb-3" id="" placeholder="Date" />
                </div>
                <div className="col-md-6">
                    <input type="number" className="form-control mb-3" id="" placeholder="numéro" />
                </div>
            </div>
            <div className="col-md-12 mt-10">
              <div className="position-relative" id="liste-table">
                <table className="table job-alert-table" style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th scope="col" className='text-center'>Réference</th>
                      <th scope="col" className='text-center'>Désignation</th>
                      <th scope="col" className='text-center'>Quantité</th>
                      <th scope="col" className='text-center'>PU Vente</th>
                      <th scope="col" className='text-center'>TVA</th>
                      <th scope="col" className='text-center'>Montant HT</th>
                      <th scope="col" className='text-center'>Image</th>
                    </tr>
                  </thead>
                  <tbody className="border-0">
                    {data.map((item, index) => (
                      <tr key={item.id} style={{ position: 'relative' }}>
                        <td className='text-center'>{item.id}</td>
                        <td className='text-center'>{item.designation}</td>
                        <td className='text-center'>{item.quantity}</td>
                        <td className='text-center'>{item.price}$</td>
                        <td className='text-center'>{item.tva}</td>
                        <td className='text-center'>{item.amount}$</td>
                        <td className='text-center'></td>
                        
                        {/* Icon outside the table aligned with each row */}
                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            right: '-30px',  // Adjust to move the icon outside the table
                          }}
                        >
                          <i 
                            className="bi bi-x-circle-fill text-danger" 
                            style={{ cursor: 'pointer', fontSize: '20px' }}
                            onClick={() => handleDelete(item.id)}
                          ></i>
                        </div>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>


          
            <div className="button-group d-flex mt-30 mb-3 ">
                <a href="#" className="dash-btn-two tran3s me-3">Ajouter un produit/service</a>                
            </div>
            <hr />
            <div className='row mt-3'>
               
                <div className="col-md-6">
                    <input type="number" className="form-control mb-3" id="" placeholder="Montant TTC" />
                </div>
                <div className="col-md-6">
                    <input type="text" className="form-control mb-3" id="" placeholder="Coordonnées bancaires" />
                </div>
                <div className="col-md-6">
                <h5><Link to="#" onClick={handleShowConditionGeneral} className="text-reset">conditions générales</Link></h5>

                </div>
                
            </div>
            
            <div className="button-group d-flex justify-content-center mt-30">
                <Link to="/ma-facturation-factures" className="dash-btn-two tran3s me-3">Enregistrer le brouillon</Link>  
                <Link to="/ma-facturation-factures" className="dash-btn-two tran3s me-3">Valider et générer la facture</Link>                
              
            </div>
        </div>
       

            </div>
            <Modal show={show} onHide={handleClose} size="xl">
              <Modal.Header closeButton>
                <Modal.Title>Indiquez les coordonées de votre client</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div className="bg-white card-box border-20 col-12">
                <form  className=" mb-3" >
              
                <div className="container mt-5">
                  <div className="row">
                  <div className="col-md-5">
            <h4 className="mb-3"><u>L'entreprise</u></h4>
            <div className="mb-3" style={{ position: 'relative', width: '100%' }}>
              <div
                className="form-select"
                onClick={() => setIsOpen(!isOpen)}
                style={{ cursor: 'pointer', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
              >
                {selected || "Raison Sociale*"}
              </div>
              {isOpen && (
                <div className="options" style={{ border: '1px solid #ccc', borderRadius: '4px', marginTop: '5px', backgroundColor: 'white' }}>
                  <div onClick={() => handleSelect("Client 1")} style={{ padding: '10px', cursor: 'pointer' }}>Client 1</div>
                  <div onClick={() => handleSelect("Client 2")} style={{ padding: '10px', cursor: 'pointer' }}>Client 2</div>
                  <div onClick={() => handleSelect("Client 3")} style={{ padding: '10px', cursor: 'pointer' }}>Client 3</div>
                  <div onClick={handleShowSearchModal} style={{ padding: '10px', cursor: 'pointer', borderTop: '1px solid #ccc' }}>
                    Recherche avancée
                  </div>
                </div>
              )}
            </div>
            <div className="mb-3">
              <input type="text" className="form-control" id="siretInput" placeholder="Siret*" />
            </div>
            <div className="mb-3">
              <input type="text" className="form-control" id="tvaInput" placeholder="N° TVA Intracommunautaire*" />
            </div>
            </div>
                {/* Address Information Section */}
                <div className="col-md-7">
                  <h4 className="mb-3" ><u>Contact</u></h4>
                        <div className="row mb-3">
                          <div className="col-md-6 mb-3">
                            <select className="form-select form-select select-select2"  aria-label="Select example" id="CivilitéSelect">
                                <option value="" disabled selected>Civilité*</option>
                                <option value="client1">C 1</option>
                                <option value="client2">C 2</option>
                            </select>     
                          </div>
                          <div className="col-md-6">
                          <select className="form-select form-select select-select2" id="fonctionSelect">
                                <option value="" disabled selected>Fonction*</option>
                                <option value="client1">Fct 1</option>
                                <option value="client2">Fct 2</option>
                            </select>  
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-6 mb-3">
                            <input type="text" className="form-control" id="ibanInput" placeholder="Prénom*" />
                          </div>
                          <div className="col-md-6">
                            <input type="text" className="form-control" id="bicInput" placeholder="Nom*" />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-6 mb-3">
                            <input type="text" className="form-control" id="bankAddressInput" placeholder="Téléphone mobile*" />
                          </div>
                          <div className="col-md-6 ">
                            <input type="text" className="form-control" id="bankAddressInput" placeholder="Téléphone Fixe*" />
                          </div>
                        </div>
                          <div className="col-md-6 mb-4">
                            <input type="text" className="form-control" id="bankAddressInput" placeholder="Email*" />
                          </div>
                        
                  
                    </div>
                  </div>
                </div>
                <div className="col-md-5">        
        <h4 className="mb-3"><u>L'adresse</u></h4>
          <div className="mb-3">
            <input type="text" className="form-control" id="adresseInput" placeholder="Adresse*" />
          </div>
          <div className="mb-3">
            <input type="text" className="form-control" id="codePostalInput" placeholder="Code Postal*" />
          </div>
          <div className="mb-3">
            <input type="text" className="form-control" id="villeInput" placeholder="Ville*" />
          </div>
                </div>
                <div className='d-flex flex-column align-items-center mb-3'>
                   <div className="d-inline-flex col-4 align-items-center justify-content-center" style={{ gap: '30px' }}>
                    <Link
                   className="text-reset"
                      to="#"
                      style={{

                        textDecoration: 'none',
                       
                      }}
                      onClick={handleClose}
                    >
                      Annuler
                    </Link>
                    <Link
                      to="/ma-facturation-factures-create"
                      style={{
                        backgroundColor: '#19AF78',
                        color: 'white',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        textDecoration: 'none',
                        transition: 'all 0.3s ease'
                      }}
                      onClick={handleClose}
                    >
                      Ajouter
                    </Link>
                  </div>
                </div>
               
          </form>
        </div>
        </Modal.Body>
      </Modal>

<Modal show={showConditionGeneral} onHide={handleCloseConditionGeneral}>
        <Modal.Header closeButton>
          <Modal.Title>Conditions générales</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Form */}
          <form>
            <h4 className="d-flex flex-column align-items-center mb-4">
              <Link to='/ma-facturation' className="text-reset">
                <u>Gérez vos échances et paiements</u>
              </Link>
            </h4>

            <div className='row'>
              <div className='col-md-5'>
                <h5 className='mb-4'><u>Conditions</u></h5>
                <div className="form-check mb-2">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    COMPTANT
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                    A RECEPTION
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                  <label className="form-check-label" htmlFor="flexRadioDefault3">
                    A 30 JOURS FIN DE MOIS
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                  <label className="form-check-label" htmlFor="flexRadioDefault4">
                    A 45 JOURS FIN DE MOIS
                  </label>
                </div>
              </div>

              <div className='col-md-7'>
                <h5><u>Text apparaissant sur la facture</u></h5>
                <div>
                  <textarea className="col-md-12 invoice-text-box" rows="5" defaultValue="• 100 % soit xxxx € à payer le : 30/11/2024 (45 jours fin de mois).&#10;(PS: zone de texte qui reste modifiable à la main)">
                  </textarea>
                </div>
                <div className='d-flex flex-column align-items-center mb-5'>
                <div className="d-inline-flex col-4 align-items-center justify-content-center" style={{ gap: '10px' }}>                <Link
                      to="#"
                      className="text-reset"
                      style={{

                        textDecoration: 'none',
                       
                      }}
                      onClick={handleCloseConditionGeneral}
                    >
                      Annuler
                    </Link>
                    <Link
                      to="#"
                      style={{
                        backgroundColor: '#19AF78',
                        color: 'white',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        textDecoration: 'none',
                        transition: 'all 0.3s ease'
                      }}
                      onClick={handleCloseConditionGeneral}
                    >
                      Ajouter
                    </Link>
                </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={ShowSearchModal} onHide={handleCloseModalSearch} size="xl">
              <Modal.Header closeButton>
                <Modal.Title>Recherche avancée</Modal.Title>
              </Modal.Header>
              <Modal.Body> <div className="modal-body">
                <form className="text-center">
                  <p className="text-center">
                    A partir du registre de l’état, notre outil est capable de retrouver vos clients à partir d’une partie du nom ou du numéro de siret de l’entreprise ou du nom du dirigeant.
                  </p>

                  <div className='row justify-content-center'>
                    <div className="mb-3 col-md-8">
                      <input className="form-control mb-3" type="text" placeholder='Renseignez un nom d’entreprise, d’un dirigeant ou du numéro de siret' />
                    </div>
                  </div>

                  {/* Radio Buttons */}
                  <div className="form-check text-start mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      value="option1"
                      checked={selectedOption === 'option1'}
                      onChange={handleOptionChange}
                    />
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Nom Société<br /> N° Siret<br /> Nom du dirigeant<br /> Adresse
                    </label>
                  </div>
                  <div className="form-check text-start mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      value="option2"
                      checked={selectedOption === 'option2'}
                      onChange={handleOptionChange}
                    />
                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                      Nom Société<br /> N° Siret<br /> Nom du dirigeant<br /> Adresse
                    </label>
                  </div>
                  <div className="form-check text-start mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault3"
                      value="option3"
                      checked={selectedOption === 'option3'}
                      onChange={handleOptionChange}
                    />
                    <label className="form-check-label" htmlFor="flexRadioDefault3">
                      Nom Société<br /> N° Siret<br /> Nom du dirigeant<br /> Adresse
                    </label>
                  </div>

                  <div className='d-flex flex-column align-items-center mb-3'>
                   <div className="d-inline-flex col-4 align-items-center justify-content-center" style={{ gap: '30px' }}>
                    <Link
                   className="text-reset"
                      to="#"
                      style={{

                        textDecoration: 'none',
                       
                      }}
                      onClick={handleCloseModalSearch}
                    >
                      Annuler
                    </Link>
                    <Link
                      to="/ma-facturation-factures-create"
                      style={{
                        backgroundColor: '#19AF78',
                        color: 'white',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        textDecoration: 'none',
                        transition: 'all 0.3s ease'
                      }}
                      onClick={handleCloseModalSearch}
                    >
                      Ajouter
                    </Link>
                  </div>
                </div>
                </form>
              </div></Modal.Body>
              </Modal>
     
      <style>{`
                 .invoice-text-box {
                    width: 100%;
                    border: 1px solid #ccc;
                    padding: 10px;
                    margin-top: 15px;
                    background-color: #f9f9f9;
                    border-radius: 5px;
                    resize: none; /* Optional: to prevent resizing */
                }
            `}</style>
    </div>
  );
};

export default Factures;