import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

// Form validation schema using Yup
const schema = Yup.object().shape({
  prenom: Yup.string().required("Prénom requis"),
  nom: Yup.string().required("Nom requis"),
  email: Yup.string().required("Email requis").email("Email invalide"),
  password: Yup.string()
    .required("Mot de passe requis")
    .min(6, "Le mot de passe doit contenir au moins 6 caractères"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Les mots de passe doivent correspondre")
    .required("Confirmation du mot de passe requise"),
});

// Error message component
const ErrorMsg = ({ msg }) => (
  <div className="error-msg">{msg}</div>
);

const RegisterForm = () => {
  const [showPass, setShowPass] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Handle form submission
  const onSubmit = (data) => {
    alert("Inscription réussie !");
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-12">
          <div className="input-group-meta position-relative mb-25">
            <label>Prénom*</label>
            <input type="text" placeholder="Jean" {...register("prenom")} />
            {errors.prenom && <ErrorMsg msg={errors.prenom.message} />}
          </div>
        </div>
        <div className="col-12">
          <div className="input-group-meta position-relative mb-25">
            <label>Nom*</label>
            <input type="text" placeholder="Dupont" {...register("nom")} />
            {errors.nom && <ErrorMsg msg={errors.nom.message} />}
          </div>
        </div>
        <div className="col-12">
          <div className="input-group-meta position-relative mb-25">
            <label>Email*</label>
            <input
              type="email"
              placeholder="jean.dupont@example.com"
              {...register("email")}
            />
            {errors.email && <ErrorMsg msg={errors.email.message} />}
          </div>
        </div>
        <div className="col-12">
          <div className="input-group-meta position-relative mb-20">
            <label>Mot de passe*</label>
            <input
              type={showPass ? "text" : "password"}
              placeholder="Entrez le mot de passe"
              {...register("password")}
            />
            <span
              className="toggle-password"
              onClick={() => setShowPass(!showPass)}
            >
              {showPass ? "Hide" : "Show"}
            </span>
            {errors.password && <ErrorMsg msg={errors.password.message} />}
          </div>
        </div>
        <div className="col-12">
          <div className="input-group-meta position-relative mb-25">
            <label>Confirmation du mot de passe*</label>
            <input
              type={showPass ? "text" : "password"}
              placeholder="Confirmez le mot de passe"
              {...register("confirmPassword")}
            />
            {errors.confirmPassword && (
              <ErrorMsg msg={errors.confirmPassword.message} />
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="agreement-checkbox d-flex justify-content-between align-items-center">
            <div>
              <input
                type="checkbox"
                {...register("terms")}
                name="terms"
                id="remember"
              />
              <label htmlFor="remember">
                En cliquant sur le bouton S'inscrire, vous acceptez les{" "}
                <a href="#">Conditions générales</a> &{" "}
                <a href="#">Politique de confidentialité</a>.
              </label>
            </div>
          </div>
        </div>
        <div className="col-12">
          <button
            type="submit"
            className="btn-eleven1 fw-500 tran3s d-block mt-20"
          >
            S'inscrire
          </button>
        </div>
      </div>
    </form>
  );
};

const RegisterArea = () => {
  return (
    <>
      <div className="hero-banner-two position-relative">
        <div className="container">
          <div
            className="position-relative pb-80 lg-pb-60"
            style={{ paddingTop: "135px", paddingBottom: "30px" }}
          >
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">
                  S’inscrire
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="registration-section position-relative pt-100 lg-pt-80 pb-150 lg-pb-80">
        <div className="container">
          <div className="user-data-form">
            <div className="text-center">
              <h2>Créer un compte</h2>
            </div>
            <div className="form-wrapper m-auto">
              <RegisterForm />
              <div className="d-flex align-items-center mt-30 mb-10">
                <div className="line"></div>
                <span className="pe-3 ps-3">OU</span>
                <div className="line"></div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <a
                    href="#"
                    className="social-use-btn d-flex align-items-center justify-content-center tran3s w-100 mt-10"
                  >
                    <img src="/assets/images/icon/google.png" alt="" />
                    <span className="ps-2">Inscription avec Google</span>
                  </a>
                </div>
                <div className="col-sm-6">
                  <a
                    href="#"
                    className="social-use-btn d-flex align-items-center justify-content-center tran3s w-100 mt-10"
                  >
                    <img src="/assets/images/icon/microsoft.png" alt="Microsoft Icon" />
                    <span className="ps-2">Inscription avec Microsoft</span>
                  </a>
                </div>
              </div>
              <p className="text-center mt-10">
                Vous avez un compte ?{" "}
                <a href="#" className="fw-500">
                  Connexion
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RegisterArea;
