import {Link,useNavigate } from 'react-router-dom';
import DashboardHeader from '../../header';
import React, { useEffect,useState  } from 'react';
import $ from 'jquery';
// import 'select2/dist/css/select2.min.css';
// import 'select2/dist/js/select2.min.js';
const InfoSocieteClient = ({ setIsOpenSidebar }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("");

  const handleSelect = (value) => {
    setSelected(value);
    setIsOpen(false);
  };

  const handleAddClick = () => {
    navigate('/ma-facturation-factures-rechercher-societe'); 
  };

  // useEffect(() => {
  //   $('#raisonSocialeSelect').select2({
  //     placeholder: 'Raison Sociale*',
  //     allowClear: true,
  //   });
  // }, []);
  

  return (
    <div className="dashboard-body">
      <div className="position-relative">
        
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

        <h2 className=" d-flex flex-column align-items-center main-title h2-titel"> Ma facturation &gt;Projet nom &gt;Information générale</h2>

        <div className="bg-white card-box border-20 col-12">
          <form >
          <h4 className="d-flex flex-column align-items-center mb-3"><u>Indiquez les coordonées de votre client</u></h4>
        
          <div className="container">
             <div className="row">
                {/* Company Information Section */}
                <div className="col-md-5">
                  <h4 className="mb-3"><u>L'entreprise</u></h4>
                  <div className="mb-3" style={{ position: 'relative', width: '100%' }}>
                      <div className="form-select"  onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}>
                        {selected || "Raison Sociale*"}
                      </div>
                      {isOpen && (
                        <div className="options" style={{ border: '1px solid #ccc', borderRadius: '4px', marginTop: '5px', backgroundColor: 'white' }}>
                          <div onClick={() => handleSelect("client1")} style={{ padding: '10px', cursor: 'pointer' }}>Client 1</div>
                          <div onClick={() => handleSelect("client2")} style={{ padding: '10px', cursor: 'pointer' }}>Client 2</div>
                          <div onClick={() => handleSelect("client3")} style={{ padding: '10px', cursor: 'pointer' }}>Client 3</div>
                          <div onClick={handleAddClick} style={{ padding: '10px', color: 'blue', cursor: 'pointer', borderTop: '1px solid #ccc' }}>Recherche avancée</div>
                        </div>
                      )}
                    </div>
                  <div className="mb-3">
                    <input type="text" className="form-control" id="siretInput" placeholder="Siret*" />
                  </div>
                  <div className="mb-3">
                    <input type="text" className="form-control" id="tvaInput" placeholder="N° TVA Intracommunautaire*" />
                  </div>
                </div>

                {/* Address Information Section */}
                <div className="col-md-7">
                  <h4 className="mb-3" ><u>Contact</u></h4>
                        <div className="row mb-3">
                          <div className="col-md-6 mb-3">
                            <select className="form-select form-select select-select2"  aria-label="Select example" id="CivilitéSelect">
                                <option value="" disabled selected>Civilité*</option>
                                <option value="client1">C 1</option>
                                <option value="client2">C 2</option>
                            </select>     
                          </div>
                          <div className="col-md-6">
                          <select className="form-select form-select select-select2" id="fonctionSelect">
                                <option value="" disabled selected>Fonction*</option>
                                <option value="client1">Fct 1</option>
                                <option value="client2">Fct 2</option>
                            </select>  
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-6 mb-3">
                            <input type="text" className="form-control" id="ibanInput" placeholder="Prénom*" />
                          </div>
                          <div className="col-md-6">
                            <input type="text" className="form-control" id="bicInput" placeholder="Nom*" />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-6 mb-3">
                            <input type="text" className="form-control" id="bankAddressInput" placeholder="Téléphone mobile*" />
                          </div>
                          <div className="col-md-6 ">
                            <input type="text" className="form-control" id="bankAddressInput" placeholder="Téléphone Fixe*" />
                          </div>
                        </div>
                          <div className="col-md-6 mb-4">
                            <input type="text" className="form-control" id="bankAddressInput" placeholder="Email*" />
                          </div>
                        
                  
                    </div>
                  </div>
                </div>
                <div className="col-md-5">        
        <h4 className="mb-3"><u>L'adresse</u></h4>
          <div className="mb-3">
            <input type="text" className="form-control" id="adresseInput" placeholder="Adresse*" />
          </div>
          <div className="mb-3">
            <input type="text" className="form-control" id="codePostalInput" placeholder="Code Postal*" />
          </div>
          <div className="mb-3">
            <input type="text" className="form-control" id="villeInput" placeholder="Ville*" />
          </div>
                </div>
                <div className='d-flex flex-column align-items-center mb-3'>
                              <div className="button-group d-inline-flex col-4  align-items-center justify-content-center">
                                <Link to="/ma-facturation-factures-create" className="text-reset me-3 card_dash">Annuler</Link>          
                                <Link to="/ma-facturation-factures-create" className="dash-btn-two tran3s me-3 card_dash">Ajouter</Link>          
                           </div>
                        </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InfoSocieteClient;
