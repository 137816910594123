import React,{useState} from 'react';
import EmployAside from '../../aside';
import DemarchesCreate from './mes-demarches-create';

const MesDemarchesCreate = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (    
    <div className='main-page-wrapper'>
      {/* aside start */}
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar}/>
      {/* aside end  */}

      {/* profile area start */}
      <DemarchesCreate setIsOpenSidebar={setIsOpenSidebar}/>
      {/* profile area end */}
    </div>    
  );
};

export default MesDemarchesCreate;