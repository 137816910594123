import React from 'react';
import Header from "./Header";
import Banner from './Banner';
import Blog from './Blog';
import Feature from './Feature';
import HowItWorksTwo from './how-it-works';
import Footer from './Footer';
import ScrollToTopButton from '../Components/Theme/Theme';
import Contactindex from '../Components/frontend/login/login-area';


function Contact() {
    return (
        <>
            <Header></Header>
            {/* <Banner></Banner> */}
            <Contactindex></Contactindex>
            {/* <Feature></Feature> */}
            <Footer></Footer>                      
            <ScrollToTopButton></ScrollToTopButton>                      
        </>
    );
}

export default Contact;