import React from 'react';
import DashboardHeader from '../header';
import { Link } from 'react-router-dom';

const Communication = ({setIsOpenSidebar}) => {
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

        <div className="d-flex align-items-center justify-content-between mb-40 lg-mb-30">
          <h2 className="main-title m0 h2-titel">Pharos WebPro</h2>          
        </div>
        <div className="bg-white card-box border-20">
          <div className="row justify-content-center">
             <div className="col-12">
                        <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                        <Link to="/info-projet">Projet 1</Link>
                        </button>
                        <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                         <Link to="#">Projet 2</Link>
                        </button>
                        <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
                         <Link to="/paiement-package-web">Commander un nouveau projet</Link>
                        </button>
                        </div>
                         
          </div>
        </div>
      
          
      </div>      
    </div>
  );
};

export default Communication;