'use client'
import React,{useState} from 'react';
import EmployAside from '../aside';
import Communication from './communication';

const MaCommunication = () => {
  const [isOpenSidebar,setIsOpenSidebar] = useState(false);
  return (    

    <div className='main-page-wrapper'>
      {/* aside start */}
      <EmployAside isOpenSidebar={isOpenSidebar} setIsOpenSidebar={setIsOpenSidebar}/>
      {/* aside end  */}

      {/* profile area start */}
      <Communication setIsOpenSidebar={setIsOpenSidebar}/>
      {/* profile area end */}
    </div>    
  );
};

export default MaCommunication;