import React from "react";
import {Link} from "react-router-dom";

const JobAlertItem = ({
  title,
  duration,
  location,
  category,
  found,
  time,
}) => {
  return (
    <tr>
      <td className='text-center'>
        <div>{title}</div>
        <div>{location}</div>        
      </td>
      <td className='text-center'>
      <div className={`job-type fw-500 ${duration === "Part-Time" ? "part-time" : ""}`}>
        <Link
          to="/mes-demarches-create"
          style={{ textDecoration: "none" }}
        >
          {category}
        </Link>
      </div>

        {/* <div>{location}</div>
        <div>{category}</div> */}
      </td>
      {/* <td>Jobs found {found}</td>
      <td>{time}</td>
      <td>
        <div className="action-dots float-end">
          <button
            className="action-btn dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span></span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end">
            <li>
              <a className="dropdown-item" href="#">
                <Image src={view} alt="icon" className="lazy-img" /> View
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                <Image src={share} alt="icon" className="lazy-img" /> Share
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                <Image src={edit} alt="icon" className="lazy-img" /> Edit
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                <Image src={delete_icon} alt="icon" className="lazy-img" />{" "}
                Delete
              </a>
            </li>
          </ul>
        </div>
      </td> */}
    </tr>
  );
};

export default JobAlertItem;
